<template>
  <!-- Content Wrapper. Contains page content -->
  <div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Booklet</h1>
          </div><!-- /.col -->
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">Home</li>
              <li class="breadcrumb-item active">Booklet</li>
            </ol>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <!-- /.content-header -->

    <!-- Main content -->
    <div class="content">
      <div class="container-fluid">
        <div class="row">
          <!-- /.col-md-6 -->
          <div class="col-lg-12">
            <div class="card">
              <Loader v-show="loading" style="height: 100%"  />
              <div class="card-header">
                <h1 class="card-title mt-2"><i class="fa fa-book"></i> Booklet Registration</h1>
                <div class="card-tools">
                  <button @click="addBooklet" class="btn btn-outline-primary ml-2"><i class="fa fa-plus"></i></button>
                </div>
              </div>
              <div class="card-body p-0">
                <table v-if="loading" class="table responsive-table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Batch</th>
                      <th scope="col">Assigned To</th>
                      <th scope="col">Starting OR</th>
                      <th scope="col">Ending OR</th>
                      <th scope="col">Available</th>
                      <th scope="col">Used</th>
                      <th scope="col">Cancelled</th>
                      <th scope="col">Date Registered</th>
                      <th scope="col">Show ORs</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="index in 10" :key="index">
                      <th scope="row"><skeleton-loader-vue type="rect" :rounded="true" :width="50" :height="25" animation="fade" /></th>
                      <td data-title="Assigned To"> <skeleton-loader-vue type="rect" :rounded="true" :width="140" :height="25" animation="fade" /></td>
                      <td data-title="Starting OR"> <skeleton-loader-vue type="rect" :rounded="true" :width="50" :height="25" animation="fade" /></td>
                      <td data-title="Ending OR "><skeleton-loader-vue type="rect" :rounded="true" :width="50" :height="25" animation="fade" /></td>
                      <td data-title="Available "><skeleton-loader-vue type="rect" :rounded="true" :width="30" :height="25" animation="fade" /></td>
                      <td data-title="Used "><skeleton-loader-vue type="rect" :rounded="true" :width="30" :height="25" animation="fade" /></td>
                      <td data-title="Cancelled "><skeleton-loader-vue type="rect" :rounded="true" :width="30" :height="25" animation="fade" /></td>
                      <td data-title="Date Registered "><skeleton-loader-vue type="rect" :rounded="true" :width="140" :height="25" animation="fade" /></td>
                      <td data-title="Show ORs "><button type="button" disabled class="btn btn-secondary btn-sm"><i class="spinner-border spinner-border-sm"></i></button></td>
                    </tr>
                  </tbody>
                </table>
                <table v-else class="table responsive-table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Batch</th>
                      <th scope="col">Assigned To</th>
                      <th scope="col">Starting OR</th>
                      <th scope="col">Ending OR</th>
                      <th scope="col">Available</th>
                      <th scope="col">Used</th>
                      <th scope="col">Cancelled</th>
                      <th scope="col">Date Registered</th>
                      <th scope="col">Show ORs</th>
                    </tr>
                  </thead>
                  <tbody v-if="booklets.total==0">
                    <tr>
                    <td colspan="9">
                        <div class="row justify-content-center mt-5">
                            <svg width="64" height="41" viewBox="0 0 64 41" xmlns="http://www.w3.org/2000/svg">
                              <g transform="translate(0 1)" fill="none" fill-rule="evenodd">
                                <ellipse fill="#F5F5F5" cx="32" cy="33" rx="32" ry="7"></ellipse>
                                <g fill-rule="nonzero" stroke="#D9D9D9">
                                  <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
                                  <path d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z" fill="#FAFAFA"></path>
                                </g>
                              </g>
                            </svg>                  
                        </div>
                        <div class="row justify-content-center mb-5">
                          <p>No record found</p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr v-for="booklet in booklets.data" :key="booklet.id">
                      <th scope="row"><b>{{ booklet.batch_no }}</b></th>
                      <td data-title="Assigned To">{{ booklet.name }}</td>
                      <td data-title="Starting OR">{{ booklet.starting_or }}</td>
                      <td data-title="Ending OR">{{ booklet.ending_or }}</td>
                      <td data-title="Available"><span class="badge badge-success">{{ booklet.active }}</span></td>
                      <td data-title="Used"><span class="badge badge-secondary">{{ booklet.used }}</span></td>
                      <td data-title="Cancelled"><span class="badge badge-danger">{{ booklet.cancelled }}</span></td>
                      <td data-title="Date Registered">{{ booklet.created | moment("YYYY-MMM-D h:mm:ss a")}}</td>
                      <td data-title="Show ORs"><button type="button" @click="viewOrdetails(booklet.batch_no)" class="btn btn-primary btn-sm"><i class="fa fa-folder-open"></i></button></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div v-show="booklets.total!=0" class="card-footer border-top">
               <pagination :data="booklets" @pagination-change-page="getBookletsResults" show-disabled :limit="2"></pagination>
              </div>
            </div>
          </div>
          <!-- /.col-md-6 -->
        </div>
        <!-- /.row -->
      </div><!-- /.container-fluid -->


      <!-- Modals -->

      <!-- booklet modal -->
      <div class="modal fade" data-backdrop="static" id="bookletmodal" tabindex="-1" role="dialog" aria-labelledby="bookletModalLabel1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-md" role="document">
          <div class="modal-content">
            <div class="modal-header bg-orange">
              <h1 class="card-title modal-title"><b>Register New Booklet</b></h1>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <form @submit.prevent="editmode ? updateBooklet() : saveBooklet()" @keydown="form.onKeydown($event)" novalidate>

            <div class="modal-body">
              <div class="row-aligncenter">

                  <div class="form-group">
                    <label for="inputBatchno">Batch Number</label> <span v-if="generate_loading" class="float-right text-success"><i class="spinner-border spinner-border-sm"></i> &nbsp;</span><span v-if="generate_loading" class="float-right text-primary"><i class="spinner-border spinner-border-sm"></i> &nbsp;</span><span v-if="generate_loading" class="float-right text-warning"><i class="spinner-border spinner-border-sm"></i> &nbsp;</span><span v-if="generate_loading" class="float-right text-danger"><i class="spinner-border spinner-border-sm"></i> &nbsp;</span>
                    <input type="text" v-model.lazy="$v.form.batch_no.$model" class="form-control" id="inputBatchno" aria-describedby="inputBatchno" :class="{'is-invalid':$v.form.batch_no.$invalid,'xxx':!$v.form.batch_no.$invalid }" readonly />
                    <div class="invalid-feedback">
                        <span class="help-block" v-if="!$v.form.batch_no.required"><i class="fa fa-exclamation-circle"></i> Batch number is required.</span>
                        <span class="help-block" v-if="!$v.form.batch_no.maxLength"><i class="fa fa-exclamation-circle"></i> Batch registration is full. Please call administrator.</span>
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="inputPreviousOR">Previous OR</label>
                    <input type="text" v-model="form.previous_or" class="form-control" id="inputPreviousOR" aria-describedby="inputPreviousOR" readonly />
                  </div>

                  <div class="form-group" :class="{'has-error':$v.form.starting_or.$error,'has-success':!$v.form.starting_or.$invalid }">
                    <label for="inputStartingOR">Starting OR</label><small class="text-danger"> (required)</small>
                    <input @keydown.enter="focusNext" type="number" v-model.lazy="$v.form.starting_or.$model" :class="{'is-invalid':$v.form.starting_or.$error,'is-valid':!$v.form.starting_or.$invalid }" class="form-control" id="inputStartingOR" aria-describedby="inputStartingOR" placeholder="Enter OR number.." autocomplete="off" />
                    <div class="invalid-feedback">
                        <span class="help-block" v-if="!$v.form.starting_or.required"><i class="fa fa-exclamation-circle"></i> Staring OR is required.</span>
                        <span class="help-block" v-if="!$v.form.starting_or.minValue"><i class="fa fa-exclamation-circle"></i> Starting OR should be greater than Previous OR value.</span>
                    </div>
                  </div>

                  <div class="form-group" :class="{'has-error':$v.form.ending_or.$error,'has-success':!$v.form.ending_or.$invalid }">
                    <label for="inputEndingOR">Ending OR</label><small class="text-danger"> (required)</small>
                    <input @keydown.enter="focusNext" type="number" v-model.lazy="$v.form.ending_or.$model" :class="{'is-invalid':$v.form.ending_or.$error,'is-valid':!$v.form.ending_or.$invalid }" class="form-control" id="inputEndingOR" aria-describedby="inputEndingOR" placeholder="Enter OR number.." autocomplete="off" />
                    <div class="invalid-feedback">
                        <span class="help-block" v-if="!$v.form.ending_or.required"><i class="fa fa-exclamation-circle"></i> Ending OR is required.</span>
                        <span class="help-block" v-if="!$v.form.ending_or.minValue"><i class="fa fa-exclamation-circle"></i> Ending OR should be greater than Starting OR value.</span>
                    </div>
                  </div>

                  <div class="form-group" :class="{'has-error':$v.form.user_id.$error,'has-success':!$v.form.user_id.$invalid }">
                    <label for="inputAssignedTo">Assigned to cashier:</label><small class="text-danger"> (required)</small>
                    <select  v-model.lazy="$v.form.user_id.$model" :class="{'is-invalid':$v.form.user_id.$error,'is-valid':!$v.form.user_id.$invalid }" class="form-control" id="inputAssignedTo" aria-describedby="inputAssignedTo">
                      <option value="">- Select user -</option>
                      <option v-for="cashier in cashiers" :key="cashier.id" v-bind:value="cashier.id">{{ cashier.name }}</option>
                    </select>
                    <div class="invalid-feedback">
                      <span class="help-block" v-if="!$v.form.user_id.required"><i class="fa fa-exclamation-circle"></i> Assigned user is required.</span>
                    </div>
                  </div>

                  <div class="form-group clearfix">
                    <div class="alert alert-warning" role="alert">
                      <div class="row">
                        <div class="col-2 mt-3">
                          <h1 class="alert-heading text-center"><i class="fa fa-exclamation-triangle"></i></h1>
                        </div>
                        <div class="col-10">
                          <small>Once registration is complete, the booklet is protected and cannot be altered. Incase of registration erratum, contact system admin immediately. Check confirm to proceed.</small>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="form-group clearfix">
                    <div class="icheck-primary d-inline">
                      <input type="checkbox" id="checkboxPrimary1" v-model="confirm">
                      <label for="checkboxPrimary1">
                        Confirm
                      </label>
                    </div>
                  </div>

              </div>
            </div>
            <div class="card-footer">
              <div class="row col-12 p-0 m-0">
                  <div class="col-8 p-0">
                  </div>
                  <div class="text-right col-12 p-0">
                    <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>&nbsp;
                    <button v-show="!editmode" type="submit" :disabled="!confirm || $v.$invalid" class="btn btn-info" ><i class="fa fa-chevron-circle-right"></i> Register</button>
                  </div>
              </div>
            </div>

            </form>

          </div>
        </div>
      </div>

      <!-- ordetails modal -->
      <div class="modal fade" data-backdrop="static" id="ordetailsmodal" tabindex="-1" role="dialog" aria-labelledby="ordetailsModalLabel1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-md" role="document">
          <div class="modal-content">
            <div class="modal-header bg-orange">
              <h1 class="card-title modal-title"><b>OR Details ({{ this.batch_no }})</b></h1>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div class="modal-body">
              <div class="row-aligncenter">
                 <table class="table table-bordered table-striped table-hover dataTable dtr-inline">
                  <thead>
                    <tr>
                      <td><b>OR Number</b></td>
                      <td><b>Status</b></td>
                      <td class="text-center"><b>Date Registered</b></td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="ors.total==0">
                      <td colspan="3"><b>No record found.</b><br/></td>
                    </tr>
                    <tr v-for="or in ors.data" :key="or.id">
                      <td>{{ or.or_no }}</td>
                      <td>
                        <span v-if="or.status == 'Active'" class="badge badge-success">{{ or.status }}</span>
                        <span v-else-if="or.status == 'Used'" class="badge badge-secondary">{{ or.status }}</span>
                        <span v-else class="badge badge-danger">{{ or.status }}</span>
                      </td>
                      <td class="text-center">{{ or.created_at | moment("YYYY-MMM-D") }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="card-footer">
              <div class="row col-12 p-0 m-0">
                  <div class="col-9 p-0">
                    <pagination :data="ors" @pagination-change-page="getResults" show-disabled :limit="2"></pagination>
                  </div>
                  <div class="text-right col-3 p-0">
                    <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>&nbsp;
                  </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    
      <!-- Modals -->



    </div>
    <!-- /.content -->
  </div>
  <!-- /.content-wrapper -->
</template>

<script>

import {mapGetters} from 'vuex'
import Form from 'vform'
import axios from 'axios'
import Loader from '@/components/Loader'
import { required, integer, minValue, maxLength } from 'vuelidate/lib/validators'
import VueSkeletonLoader from 'skeleton-loader-vue';

export default {
  name: 'Booklet',

  components: {
    Loader, VueSkeletonLoader,
  },

  data() {
    return {
      skeleton: true,
      loading: false,
      editmode: false,
      generate_loading: false,
      confirm : false,
      booklets : {},
      cashiers: {},
      ors : {},
      batch_no: '',
      form: new Form({
        batch_no: 'B00001',
        previous_or: '',
        starting_or: '',
        ending_or: '',
        user_id: '',
      }),
    }
  },
  validations() {
    return {
      form: {
        batch_no: { required, maxLength: maxLength(6) },
        starting_or: { required, integer, minValue : minValue(this.greaterThanPreviousOR) },
        ending_or: { required, integer, minValue : minValue(this.greaterThanStartingOR) },
        user_id: { required, integer },
      }
    }
  },

  computed: {
    ...mapGetters(['user']),

    greaterThanStartingOR() {
      return parseInt(this.form.starting_or) + 1;
    },
    greaterThanPreviousOR() {
      return parseInt(this.form.previous_or) + 1;
    },
  },

  methods: {

    editBooklet(booklet) {
      this.editmode = true;
      this.confirm = false;
      this.form.reset();
      this.form.clear();
      this.form.fill(booklet);
      $('#bookletmodal').modal('show');
    },

    addBooklet() {
      this.$v.$reset();
      this.editmode = false;
      this.form.reset();
      this.form.clear();
      this.confirm = false;
      this.generateBatchno();
      $('#bookletmodal').modal('show');
    },

    saveBooklet() {
      swal.fire({
        title: 'Confirmation',
        text: "Once registration is complete, the booklet is protected and cannot be altered. Incase of registration erratum, contact system admin immediately. Check confirm to proceed.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirm',
        cancelButtonText: 'No'
        }).then((result)=>{
          if (result.value) {
            this.$Progress.start();
            try {
              this.form.post('api/booklets')
              .then(()=>{
                    this.$swal.fire({
                      icon: 'success',
                      title: 'New booklet saved'
                    })
                    this.$Progress.finish();
                    this.loadBookletData();
                    $('#bookletmodal').modal('hide')
              }).catch(()=>{
                    this.$swal.fire({
                      icon: 'error',
                      title: 'New booklet failed'
                    });
                    this.$Progress.fail();
              })
            } catch (e) {
                this.error = e.response.data.message;
                this.$Progress.fail();
            }
          }  
      })
    },

    toggleToast() {
      this.$swal.fire({
        icon: 'info',
        title: 'Setup this view first'
      })
    },

    async generateBatchno() {
      var prefix = 'B';
      var code;
      var masked_code;

      this.generate_loading= true;
      await axios.get('api/booklets/generate/batch/' + prefix).then(response => {
        if (!response.data) {
          this.form.batch_no = prefix + '00001';
          this.form.previous_or = 0;
        } else {
          code = response.data.batch_no.slice(response.data.batch_no.length - 5);
          code = parseInt(code) + 1

          if (code > 99999) {
            this.form.batch_no = 'Batch regsitration is full';
          } else {
            masked_code = this.zeroPad(code, 5);
            this.form.batch_no = prefix + masked_code;
            this.form.previous_or = parseInt(response.data.or_no);
            this.form.starting_or = parseInt(response.data.or_no) + 1;
          }
        }
      });
      this.generate_loading= false;
    },

    zeroPad(num, places) {
      var zero = places - num.toString().length + 1;
      return Array(+(zero > 0 && zero)).join("0") + num;
    },

    async loadData() {
      this.loading = true;
      let page = this.booklets.current_page;
      // load and set bookings data
      await axios.get('api/booklets').then(({ data }) => (this.booklets = data));
      // load user/cashier data
      await axios.get('api/users/role/Cashier').then(({ data }) => (this.cashiers = data));
      this.loading = false;
    },

    async loadBookletData() {
      this.$Progress.start();
      // load and set bookings data
      await axios.get('api/booklets').then(({ data }) => (this.booklets = data));
      this.$Progress.finish();
    },

    // Edit modal reading
    async viewOrdetails(batch) {
      this.batch_no = batch;
      this.$Progress.start();
      let page = this.ors.current_page;
      await axios.get('api/booklets/batch/' + batch + '?page=' + page).then(({ data }) => (this.ors = data));
      $('#ordetailsmodal').modal('show');
      this.$Progress.finish();
    },

    getResults(page = 1) {
      this.loading = true;
      this.$Progress.start();
      axios.get('api/booklets/batch/' + this.batch_no + '?page=' + page).then(({ data }) => (this.ors = data));
      this.$Progress.finish();
      this.loading = false;
    },

    async getBookletsResults(page = 1) {
      this.loading = true;
      this.$Progress.start();
      await axios.get('api/booklets?page=' + page).then(({ data }) => (this.booklets = data));
      this.$Progress.finish();
      this.loading = false;
    },

  },

  mounted() {
    this.$Progress.start();
    this.loadData();
    this.$Progress.finish();
    this.interval = setInterval(() => this.loadData(), 300000); // load data every 5mins
  },

  beforeDestroy() {
    clearInterval(this.interval)
  },

}
</script>
<style scoped>
.skeleton {
  width: 100% !important;
}
ul {
    margin-bottom: 0rem !important;
}
</style>
