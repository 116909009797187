<template>
  <!-- Content Wrapper. Contains page content -->
  <div class="content-wrapper">

    <!-- Modals -->
    <!-- payment modal -->
    <div class="modal fade" data-backdrop="static" id="paymentmodal" tabindex="-1" role="dialog" aria-labelledby="paymentModalLabel1" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content">
          <form @submit.prevent="viewmode ? viewPayment() : savePayment()" @keydown="form_payment.onKeydown($event)">
            <div class="modal-header bg-orange">
              <h1 class="card-title modal-title" v-show="!viewmode"><i class="fa fa-credit-card-alt"></i> OR No. <span v-if="form_payment.or_no=='DEPLETED'" class="text-danger">{{ form_payment.or_no }}</span><span v-else>{{ form_payment.or_no }}</span> (New Transaction)</h1>
              <h1 class="card-title modal-title" v-show="viewmode"><i class="fa fa-credit-card-alt"></i> OR No. 67500</h1> 
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
            <div class="container-fluid">
              <div class="row">
                <div class="col-sm-6 custom-scrollbar" id="style-7">
                  <div v-if="loading_pending_bills" class="card-body table-responsive p-0">
                    <div class="alert alert-info alert-dismissible">
                      <h5><i class="spinner-border spinner-border-sm"></i> Loading . . .</h5>
                      Retrieving all pending payments. Please wait.
                    </div>
                  </div>
                  <table  v-else class="table table-striped table-hover">
                    <div v-if="readings.total==0" class="card-body p-0 bg-info" height="100%">
                      <div class="row justify-content-center mt-5">
                          <svg width="64" height="41" viewBox="0 0 64 41" xmlns="http://www.w3.org/2000/svg">
                            <g transform="translate(0 1)" fill="none" fill-rule="evenodd">
                              <ellipse fill="#F5F5F5" cx="32" cy="33" rx="32" ry="7"></ellipse>
                              <g fill-rule="nonzero" stroke="#D9D9D9">
                                <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
                                <path d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z" fill="#FAFAFA"></path>
                              </g>
                            </g>
                          </svg>                  
                      </div>
                      <div class="row justify-content-center mb-5 text-muted">
                        <p>No records found</p>
                      </div>
                    </div>
                    <tbody v-else v-for="reading in readings_all.data" :key="reading.id">
                      <tr v-if="reading.active==1 && !reading.transactions.length">
                        <td class="text-left">
                          <img v-if="!reading.transactions.length && reading.active==1" width="55px" :src="require('@/assets/stamp-pending.png')" alt="" class="brand-image" />
                          <img v-else-if="!reading.transactions.length && reading.active==0" width="55px" :src="require('@/assets/stamp-disconnected.png')" alt="" class="brand-image" />
                          <img v-else-if="!reading.transactions.length && reading.active==2" width="55px" :src="require('@/assets/stamp-reset.png')" alt="" class="brand-image" />
                          <img v-else width="55px" :src="require('@/assets/stamp-paid.png')" alt="" class="brand-image" />
                        </td>
                        <td class="text-left">
                          <b>{{ reading.created_at | moment("YYYY-MMM-D") }}</b><br/>
                          <small>{{ reading.consumption  }} cubic m.</small>
                        </td>
                        <td class="text-right">
                          <h2 v-if="!reading.transactions.length && reading.active==1" class="text-danger">₱{{ reading.amountdue | toMoney }}</h2>
                          <h2 v-else-if="reading.active==0" class="text-secondary">₱{{ reading.amountdue | toMoney }}</h2>
                          <h2 v-else class="text-success">₱{{ reading.amountdue | toMoney }}</h2>
                        </td>
                        <td class="text-right">
                          <!--
                          <button title="View receipt" type="button" v-show="reading.payments" @click="viewPayment(reading)" class="btn btn-success btn-sm mt-2 ml-2" v-tooltip="{ content: '<b>Show Receipt</b>', placement: 'top', classes: ['info'], targetClasses: ['it-has-a-tooltip'], offset: 10, delay: { show: 500, hide: 300, }, }"><i class="fa fa-qrcode"></i></button>
                          <button title="View billing statement" type="button" @click="viewReading(reading)" class="btn btn-info btn-sm mt-2 ml-2" v-tooltip="{ content: '<b>Show Billing Statement</b>', placement: 'top', classes: ['info'], targetClasses: ['it-has-a-tooltip'], offset: 10, delay: { show: 500, hide: 300, }, }"><i class="fa fa-folder-open"></i></button>
                          -->
                          <button v-if="(!form_payment.trxns.some(data => data.id === reading.id))" title="Add to transaction" type="button" :disabled="user.role === 'Reader' || user.role === 'Staff' || form_payment.trxns.length > 3"  @click="addTrxn(reading.id, reading.created_at, reading.pres_reading, reading.prev_reading, reading.consumption, reading.discount, reading.currentcharge, reading.due_at)" class="btn btn-primary btn-sm mt-2" v-tooltip="{ content: '<b>Add to transaction</b>', placement: 'top', classes: ['info'], targetClasses: ['it-has-a-tooltip'], offset: 10, delay: { show: 500, hide: 300, }, }"><i class="fa fa-plus"></i></button>
                          <button v-else title="Remove to transaction" type="button" :disabled="user.role === 'Reader' || user.role === 'Staff'"  @click="removeTrxn(reading.id)" class="btn btn-danger btn-sm mt-2" v-tooltip="{ content: '<b>Remove to transaction</b>', placement: 'top', classes: ['info'], targetClasses: ['it-has-a-tooltip'], offset: 10, delay: { show: 500, hide: 300, }, }"><i class="fa fa-remove"></i></button>
                        </td>
                        
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="col-sm-6">
                  <div class="row-aligncenter">
                      <div class="card-body table-responsive p-0" id="printReceipt">
                        <div v-if="loading_check_or" class="card-body table-responsive p-0">
                          <div class="alert alert-info alert-dismissible">
                            <h5><i class="spinner-border spinner-border-sm"></i> Loading . . .</h5>
                            Checking available OR for the new transaction.
                          </div>
                        </div>
                        <div v-show="this.form_payment.or_no=='DEPLETED' && !loading_check_or" class="card-body table-responsive p-0">
                          <div class="alert alert-danger alert-dismissible">
                            <h5><i class="icon fa fa-exclamation-triangle"></i> OR has depleted</h5>
                            Please call Supervisor to register new OR booklet.
                          </div>
                        </div>
                        <div v-show="this.form_payment.or_no!='DEPLETED' && !loading_check_or" class="card-body table-responsive p-0">
                          <div class="alert alert-primary alert-dismissible">
                            <h5><i class="icon fa fa-check"></i> OR assigned</h5>
                            This transaction is assigned to OR No. {{ form_payment.or_no }}.
                          </div>
                        </div>
                        <table class="table borderless col-12 mb-2">
                          <tbody>
                            <tr> 
                              <td class="p-0" colspan="2">
                                <h3 class="text-secondary">OR No. <span v-if="form_payment.or_no=='DEPLETED'" class="text-danger">{{ form_payment.or_no }}</span><span v-else> {{ form_payment.or_no }}</span></h3>
                                <b>Payment Date:</b> {{ form_payment.or_date | customdate2 }}<br/>
                                <b>Consumer:</b> {{ getFullname() | bigcaps | truncate(50, '...') }}<br/>
                                <b>Address:</b> {{ consumer.Location }}<br/>
                                <b>Sr.Citizen TIN:</b> {{ form_payment.senior_no }}<br/>
                                <b>OSCA/PWD ID No.:</b> {{ form_payment.pwd_no }}<br/>
                                <span class="big"><b>Total amount paid: <mark>{{ getTotalAmountPaid | toMoney }}</mark></b></span><br/>
                                <b>Amount in words:</b> {{ form_payment.fee_total | toWordsPesos }} {{ form_payment.fee_total | toWordsCentavos }}<br/><br/>
                              </td>
                            </tr>
                            <tr>
                              <td class="p-0"><b>Membership Fee</b></td>
                              <td class="p-0" style="border-bottom: 1px solid #000000 !important;" align="right"><input v-model.lazy="form_payment.fee_membership" v-money="money" class="inputreceipt form-control" type="text" size="30" :readonly="viewmode" /></td>
                            </tr>
                            <tr>
                              <td class="p-0"><b>Connection Fee</b></td>
                              <td class="p-0" style="border-bottom: 1px solid #000000 !important;" align="right"><input v-model.lazy="form_payment.fee_connection" v-money="money" class="inputreceipt form-control" type="text" size="30" :readonly="viewmode" /></td>
                            </tr>
                            <tr>
                              <td class="p-0"><b>Reconnection Fee</b></td>
                              <td class="p-0" style="border-bottom: 1px solid #000000 !important;" align="right"><input v-model.lazy="form_payment.fee_reconnection" v-money="money" class="inputreceipt form-control" type="text" size="30" :readonly="viewmode" /></td>
                            </tr>
                            <tr>
                              <td class="p-0"><b>Service Fee</b></td>
                              <td class="p-0" style="border-bottom: 1px solid #000000 !important;" align="right"><input v-model.lazy="form_payment.fee_service" v-money="money" class="inputreceipt form-control" type="text" size="30" :readonly="viewmode" /></td>
                            </tr>
                            <tr>
                              <td class="p-0"><b>Threading Fee</b></td>
                              <td class="p-0" style="border-bottom: 1px solid #000000 !important;" align="right"><input v-model.lazy="form_payment.fee_threading" v-money="money" class="inputreceipt form-control" type="text" size="30" :readonly="viewmode" /></td>
                            </tr>
                            <tr>
                              <td class="p-0"><b>Water Meter</b></td>
                              <td class="p-0" style="border-bottom: 1px solid #000000 !important;" align="right"><input v-model.lazy="form_payment.fee_meter" v-money="money" class="inputreceipt form-control" type="text" size="30" :readonly="viewmode" /></td>
                            </tr>
                            <tr>
                              <td class="p-0"><b>Materials</b></td>
                              <td class="p-0" style="border-bottom: 1px solid #000000 !important;" align="right"><input v-model.lazy="form_payment.fee_materials" v-money="money" class="inputreceipt form-control" type="text" size="30" :readonly="viewmode" /></td>
                            </tr>
                            <tr>
                              <td class="p-0"><b>Others</b></td>
                              <td class="p-0" style="border-bottom: 1px solid #000000 !important;" align="right"><input v-model.lazy="form_payment.fee_others" v-money="money" class="inputreceipt form-control" type="text" size="30" :readonly="viewmode" /></td>
                            </tr>
                          </tbody>
                        </table>

                        <span><b>Transaction Count: {{ form_payment.trxns.length }}</b></span><br/>

                        <table class="bordered col-12" style="width:100%;border-spacing: 0;">
                          <thead>
                            <tr>
                              <!--<td style="border: 1px solid #989898 !important;"><b>Id</b></td>-->
                              <td style="border: 1px solid #989898 !important;"><b>Date</b></td>
                              <td style="border: 1px solid #989898 !important;"><b>Prev.</b></td>
                              <td style="border: 1px solid #989898 !important;"><b>Pres.</b></td>
                              <td style="border: 1px solid #989898 !important;"><b>Con.</b></td>
                              <td style="border: 1px solid #989898 !important;"><b>Rate</b></td>
                              <td style="border: 1px solid #989898 !important;"><b>Dis.</b></td>
                              <td style="border: 1px solid #989898 !important;"><b>Sur.</b></td>
                              <td style="border: 1px solid #989898 !important;"><b>Amt.</b></td>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="trxn in this.form_payment.trxns" :key="trxn.id">
                              <!--<td style="border: 1px solid #989898 !important;">{{ trxn.id }}</td>-->
                              <td style="border: 1px solid #989898 !important;">{{ trxn.reading_date | customdate2}}</td>
                              <td style="border: 1px solid #989898 !important;">{{ trxn.pres_reading }}</td>
                              <td style="border: 1px solid #989898 !important;">{{ trxn.prev_reading }}</td>
                              <td style="border: 1px solid #989898 !important;">{{ trxn.consumption }}</td>
                              <td style="border: 1px solid #989898 !important;">{{ trxn.currentcharge }}</td>
                              <td v-if="trxn.discount === '0.00'" style="border: 1px solid #989898 !important;">{{ trxn.discount }}</td>
                              <td v-else style="border: 1px solid #989898 !important;" class="text-success">{{ trxn.discount }}</td>
                              <td v-if="trxn.surcharge === '0.00'" style="border: 1px solid #989898 !important;">{{ trxn.surcharge }}</td>
                              <td v-else style="border: 1px solid #989898 !important;" class="text-danger">{{ trxn.surcharge }}</td>
                              <td style="border: 1px solid #989898 !important;">{{ trxn.amountdue }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                  </div>

                    
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="row col-12 p-0 m-0">
                <div class="col-8 p-0">
                  <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>&nbsp;
                  <button type="button" :disabled="this.form_payment.or_no=='DEPLETED'||loading_pending_bills||loading_check_or" @click="cancelOr(form_payment.or_no)" v-show="!viewmode" class="btn btn-default bg-danger">Cancel this OR</button>
                </div>
                <div class="text-right col-4 p-0">

                  <button type="button" v-show="viewmode"
                  onclick="printJS({
                    printable: 'printReceipt', 
                    type:'html',
                    style: 'html {transform: scale(.6);transform-origin: left; .small {font-size: 5px !important;}} body{position:absolute!important;top:0!important;left:0!important;} #printReceipt {position:absolute!important;top:0!important;left:0!important;overflow-y:visible!important;overflow-x:visible !important;} #printReceipt::-webkit-scrollbar {display: none;}',
                    targetStyles: ['*']
                    })"  
                  class="btn btn-success"><i class="fa fa-print mr-1"></i> Print Receipt</button>&nbsp; <!-- @click="print"  onclick="print()"  v-print="'#printReceipt'" -->
                  
                  <button type="submit" :disabled="this.form_payment.or_no=='DEPLETED'||loading_pending_bills||loading_check_or" v-show="!viewmode" class="btn btn-info"><i class="fa fa-chevron-circle-right"></i> Submit</button>
                </div>
            </div>
          </div>

          </form>

        </div>
      </div>
    </div>



    <!-- reading modal -->
    <div class="modal fade" data-backdrop="static" id="readingmodal" tabindex="-1" role="dialog" aria-labelledby="readingModalLabel1" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-md" role="document">
        <div class="modal-content">
          <div class="modal-header bg-orange">
            <h1 v-show="!readingeditmode" class="card-title modal-title">New Reading for <b>{{ consumer.Consumercode }}</b></h1>
            <h1 v-show="readingeditmode" class="card-title modal-title">Billing statement for <b>{{ this.form_reading.created_at | customdate }}</b></h1>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <form @submit.prevent="readingeditmode ? updateReading() : saveReading()" @keydown="form_reading.onKeydown($event)">
          <div style="background-color:#DEDEDE;padding-bottom: 20px">

            <div class="card-body table-responsive pt-3 pl-3 pr-3 pb-1">
              <div class="alert alert-warning alert-dismissible">
                <i class="icon fa fa-exclamation-triangle"></i>Charges are excempted if the service is disconnected.
              </div>
            </div>

            <div class="modal-body" style="width: 340px !important;margin: 0 auto 0 !important;background-color:#FFFFFF;border: 2px dashed #999999;">
              <div class="row-aligncenter">
                <iframe id="print-iframe" width="0" height="0" frameBorder="0"></iframe>
                <div id="printBilling" class="text-dark">
                  
                  <table width="100%">
                    <tr>
                      <td width="10%" style='text-align:center; vertical-align:middle'><img :src="require('@/assets/logo2.png')" width="50px" /></td>
                      <td width="90%" style='font-family:monospace;font-size:11px;text-align:center; vertical-align:middle'><span class=""><b>AS-IS BALAYONG BWP-RURAL<br/>WATERWORKS AND SANITATION<br/>ASSOCIATION, INC.</b></span></td>
                    </tr>
                  </table>
                  <table width="100%">
                    <tr>
                      <td colspan="2" style='font-family:monospace;font-size:10px;text-align:center; vertical-align:middle'><i>As-is, Bauan, Batangas</i><br/><br/></td>
                    </tr>
                    <tr style='font-family:monospace;' v-show="readingeditmode">
                      <td><b>DATE</b></td><td style='text-align:right; vertical-align:middle'>{{ todaydate | customdate2 }}</td>
                    </tr>
                    <tr style='font-family:monospace;'>
                      <td valign="top"><b>ACCT NO</b></td><td style='text-align:right; vertical-align:middle'>{{ consumer.Consumercode }}</td>
                    </tr>
                    <tr style='font-family:monospace;'>
                      <td valign="top"><b>CONSUMER</b></td><td style='text-align:right; vertical-align:middle'><b>{{ getFullname() | bigcaps  }}</b></td> <!-- | truncate(20, '..') -->
                    </tr>
                    <tr style='font-family:monospace;' v-if="!readingeditmode">
                      <td><b>SERVICE</b></td><td style='text-align:right; vertical-align:middle'><span v-if="consumer.Active == 0" class="float-right text-default"><b>DISCONNECTED</b></span><span v-else class="float-right text-default">CONNECTED</span></td>
                    </tr>
                    <tr style='font-family:monospace;' v-else>
                      <td><b>SERVICE</b></td><td style='text-align:right; vertical-align:middle'><span v-if="this.form_reading.active == 0" class="float-right text-default"><b>DISCONNECTED</b></span><span v-else class="float-right text-default">CONNECTED</span></td>
                    </tr>
                    <tr style='font-family:monospace;'>
                      <td><b>ADDRESS</b></td><td style='text-align:right; vertical-align:middle'>{{ consumer.Address | bigcaps | truncate(20, '..')}}</td>
                    </tr>
                    <tr style='font-family:monospace;'>
                      <td><b>READING DATE</b></td><td style='text-align:right; vertical-align:middle'>{{ this.form_reading.created_at | customdate2 }}</td>
                    </tr>
                    <tr style='font-family:monospace;'>
                      <td><b>DUE DATE</b></td><td style='text-align:right; vertical-align:middle'>{{ this.form_reading.due_at | customdate2 }}</td>
                    </tr>
                  </table>
                  <div v-if="!readingeditmode" class="attachment-block clearfix pl-4 pr-4 pt-3 bg-light hideinprint">
                    <div class="card-body table-responsive p-0">
                      <div class="form-group">
                        <label for="exampleInputEmail1">PRESENT READING</label>
                        <input type="text" v-model="form_reading.pres_reading" @input="computeReading" class="form-control bg-light" id="inputReading" aria-describedby="inputReading" placeholder="Enter reading" autocomplete="off" />
                        <small id="Help" class="form-text text-muted">This will be recorded today {{ this.form_reading.created_at }}.</small>
                      </div>
                      <div class="form-group clearfix">
                        <div class="icheck-primary d-inline">
                          <input type="checkbox" id="checkboxPrimary1" v-model="confirm">
                          <label for="checkboxPrimary1">
                            Confirm to submit
                          </label>
                        </div>
                        
                      </div>
                    </div>
                  </div>
                  <table width="100%">
                    <tr style='font-family:monospace;' class="hideinprint" v-show="readingeditmode && (user.role === 'Admin' || user.role === 'Supervisor')">
                      <td><b>EDIT PRESENT READING</b></td><td style='text-align:right; vertical-align:middle; border-bottom: 1px solid #DEDEDE !important; width:70px;'><input type="text" v-model="form_reading.pres_reading" @input="computeReading" class="inputreceipt form-control text-primary" style="background-color:#F2F1DD;text-align:right;padding-right: 0;" id="inputReading" aria-describedby="inputReading" placeholder="" autocomplete="off" /></td>
                    </tr>
                    <tr style='font-family:monospace;'>
                      <td><b>PRESENT READING</b></td><td style='text-align:right; vertical-align:middle'>{{ this.form_reading.pres_reading }}</td>
                    </tr>
                    <tr style='font-family:monospace;'>
                      <td><b>PREVIOUS READING</b></td><td style='text-align:right; vertical-align:middle'>{{ this.form_reading.prev_reading }}</td>
                    </tr>
                    <tr style='font-family:monospace;'>
                      <td><b>WATER CONSUMED</b></td><td style='text-align:right; vertical-align:middle'>{{ this.form_reading.consumption }}mᶟ</td>
                    </tr>
                    <tr style='font-family:monospace;'>
                      <td><span class="mid"><b>CURRENT CHARGES</b></span></td><td style='text-align:right; vertical-align:middle'><span class="mid"><b>{{ this.form_reading.currentcharge | toMoney }}</b></span></td>
                    </tr>
                    <tr style='font-family:monospace;'>
                      <td><span v-show="consumer.Consumertype == 'Residential' && this.form_reading.discount_type != 'NA'" class="text-default mid"><b>{{this.form_reading.discount_type | bigcaps}} ({{ this.form_reading.discount }}%)</b>&nbsp;</span></td><td style='text-align:right; vertical-align:middle'><span v-show="consumer.Consumertype == 'Residential' && this.form_reading.discount_type != 'NA'" class="float-right text-default mid"><b>{{ computeDiscount(this.form_reading.discount, this.form_reading.currentcharge, consumer.consumertype) | toMoney }}</b></span></td>
                    </tr>
                    <tr style='font-family:monospace;'>
                      <td><span class="mid"><b>TOTAL AMOUNT DUE</b></span></td><td style='text-align:right; vertical-align:middle'><span class="mid"><b>{{ this.form_reading.amountdue | toMoney }}</b></span></td>
                    </tr>
    
                  </table>

                  <span class="text-default"></span><span class="float-right text-default"></span><br/>
                  <span style='font-family:monospace;' class="text-default"><b>Make remittance to the office of the waterworks.</b></span><br/><br/>
                  <small style='font-family:monospace;'>***NOTE: Payable on or before the {{ this.form_reading.due_at | customdate3 }} to avoid penalties 20% total amount due.</small><br/><br/>
                  <div style="text-align: center;"><span style='font-family:monospace;color:#CC0000;' class="big"><b>DISCONNECTION DATE:</b><br/><b>{{ this.disconnection | customdate }}</b></span></div>
                </div>

              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="row col-12 p-0 m-0">
                <div class="col-8 p-0">
                  
                </div>
                <div class="text-right col-12 p-0">
                  
                  <button type="button" class="btn btn-default mr-2" data-dismiss="modal">Close</button>
                  <button v-show="readingeditmode && reading_update==true && (user.role === 'Admin' || user.role === 'Supervisor')" type="submit" :disabled="!form_reading.pres_reading || form_reading.pres_reading < form_reading.prev_reading" class="btn btn-info mr-2" ><i class="fa fa-chevron-circle-right"></i> Update</button>
                  <button v-show="readingeditmode" type="button" class="btn btn-success ml-1 mr-1" @click="samplePrint();"><i class="fa fa-print mr-1"></i>  Print</button>
                  <button v-show="!readingeditmode" type="submit" :disabled="!confirm || loading || !form_reading.pres_reading || form_reading.pres_reading < form_reading.prev_reading" class="btn btn-info" ><i class="fa fa-chevron-circle-right"></i> Submit</button>
                </div>
            </div>
          </div>
          

          </form>


        </div>
      </div>
    </div>

    <!-- billing statements modal -->
    <div class="modal fade" data-backdrop="static" id="billingmodal" tabindex="-1" role="dialog" aria-labelledby="billingModalLabel1" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-md" role="document">
        <div class="modal-content">
          <div class="modal-header bg-orange">
            <h1 class="card-title modal-title"><i class="fa fa-tachometer"></i> Edit Reading Values</h1> 
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <form @submit.prevent="updateBilling()" @keydown="form_billing.onKeydown($event)">

          <div class="modal-body">
            <div class="row-aligncenter">

              <div class="card-body table-responsive p-0">
                <div class="form-group">
                  <label for="exampleInputEmail1">Reading Date</label><span class="float-right">{{ form_billing.created_at | customdate }}</span>
                  <!--
                  <input type="text" v-model="form_billing.created_at" class="form-control" id="inputDateReading" aria-describedby="inputDateReading" placeholder="Date of reading" readonly>
                  <small id="Help" class="form-text text-muted">Editing of reading date is not allowed in testing.</small>
                  -->
                </div>
              </div>

              <div class="card-body table-responsive p-0">
                <div class="form-group">
                  <label for="exampleInputEmail1">Due Date</label><span class="float-right">{{ form_billing.due_at | customdate  }}</span>
                </div>
              </div>

              <div class="card-body table-responsive p-0">
                <div class="form-group">
                  <label for="exampleInputEmail1">Present Reading</label><span class="float-right"><input type="text" v-model="form_billing.pres_reading" class="form-control" id="inputPresReading" aria-describedby="inputPresReading" placeholder="Present reading"></span>
                  
                </div>
              </div>
              <small id="Help" class="form-text text-muted">Present reading must be greater than or equal to previous billing.</small><br/>

              <div class="card-body table-responsive p-0">
                <div class="form-group">
                  <label for="exampleInputEmail1">Previous Reading</label><span class="float-right">{{ form_billing.prev_reading }}</span>
                </div>
              </div>

              <div class="card-body table-responsive p-0">
                <div class="form-group">
                  <label for="exampleInputEmail1">Water Consumed</label><span class="float-right">{{ form_billing.pres_reading - form_billing.prev_reading  }} mᶟ</span>
                </div>
              </div>

            </div>
            
          </div>
          <div class="card-footer">
            <div class="row col-12 p-0 m-0">
                <div class="col-8 p-0">
                  
                </div>
                <div class="text-right col-12 p-0">
                  <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>&nbsp;
                  <button v-show="user.role === 'Admin'" type="submit" :disabled="!form_billing.pres_reading || form_billing.pres_reading < form_billing.prev_reading" class="btn btn-info" ><i class="fa fa-chevron-circle-right"></i> Save</button>&nbsp;
                </div>
            </div>
          </div>

          </form>


        </div>
      </div>
    </div>

    <!-- consumer modal -->
      <div class="modal fade" data-backdrop="static" id="consumermodal" tabindex="-1" role="dialog" aria-labelledby="consumerModalLabel1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-md" role="document">
          <div class="modal-content">
            <div class="modal-header bg-orange">
              <h1 class="card-title modal-title"><b>Edit Consumer</b> {{ form_consumer.Consumercode }}</h1>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <form @submit.prevent="updateConsumer()" novalidate>

            <div class="modal-body">
              <div class="row-aligncenter">
                <div class="card-body table-responsive p-0">

                  <div class="form-group" :class="{'has-error':$v.form_consumer.Consumertype.$error,'has-success':!$v.form_consumer.Consumertype.$invalid }">
                    <label for="inputConsumerType">Consumer Type</label><span class="text-danger">*</span>
                    <select  v-model.lazy="$v.form_consumer.Consumertype.$model" :class="{'is-invalid':$v.form_consumer.Consumertype.$error,'is-valid':!$v.form_consumer.Consumertype.$invalid }" class="form-control" id="inputConsumerType" aria-describedby="inputConsumerType">
                      <option value="">- Select Consumer Type -</option>
                      <option value="Residential">Residential</option>
                      <option value="Commercial/Industrial">Commercial/Industrial</option>
                    </select>
                    <div class="invalid-feedback">
                      <span class="help-block" v-if="!$v.form_consumer.Consumertype.required"><i class="fa fa-exclamation-circle"></i> Consumer type is required.</span>
                    </div>
                  </div>

                  <div class="form-group" :class="{'has-error':$v.form_consumer.Pipe.$error,'has-success':!$v.form_consumer.Pipe.$invalid }">
                    <label for="inputPipe">Pipe Size</label><span class="text-danger">*</span>
                    <select  v-model.lazy="$v.form_consumer.Pipe.$model" :class="{'is-invalid':$v.form_consumer.Pipe.$error,'is-valid':!$v.form_consumer.Pipe.$invalid }" class="form-control" id="inputPipe" aria-describedby="inputPipe">
                      <option value="">- Select Pipe Size -</option>
                      <option value='1/2"'>1/2"</option>
                      <option value='2"'>2"</option>
                    </select>
                    <div class="invalid-feedback">
                      <span class="help-block" v-if="!$v.form_consumer.Pipe.required"><i class="fa fa-exclamation-circle"></i> Pipe size is required.</span>
                    </div>
                  </div>

                  <div class="form-group" :class="{'has-error':$v.form_consumer.Firstname.$error,'has-success':!$v.form_consumer.Firstname.$invalid }">
                    <label for="inputFirstname">First Name</label><span class="text-danger">*</span>
                    <input type="text"  v-model.lazy="$v.form_consumer.Firstname.$model" class="form-control" id="inputFirstname" aria-describedby="inputFirstname" :class="{'is-invalid':$v.form_consumer.Firstname.$error,'is-valid':!$v.form_consumer.Firstname.$invalid }" placeholder="First name" autocomplete="off" />
                    <div class="invalid-feedback">
                        <span class="help-block" v-if="!$v.form_consumer.Firstname.required"><i class="fa fa-exclamation-circle"></i> First name is required.</span>
                        <span class="help-block" v-if="!$v.form_consumer.Firstname.minLength"><i class="fa fa-exclamation-circle"></i> First name must have at least {{$v.form_consumer.Firstname.$params.minLength.min}} characters.</span>
                        <span class="help-block" v-if="!$v.form_consumer.Firstname.maxLength"><i class="fa fa-exclamation-circle"></i> First name must not exeed to {{$v.form_consumer.Firstname.$params.maxLength.max}} characters.</span>
                    </div>
                  </div>

                  <div class="form-group" :class="{'has-error':$v.form_consumer.Lastname.$error,'has-success':!$v.form_consumer.Lastname.$invalid }">
                    <label for="inputLastname">Last Name</label><span class="text-danger">*</span>
                    <input type="text" v-model.lazy="$v.form_consumer.Lastname.$model" class="form-control" id="inputLastname" aria-describedby="inputLastname" :class="{'is-invalid':$v.form_consumer.Lastname.$error,'is-valid':!$v.form_consumer.Lastname.$invalid }" placeholder="Last name" autocomplete="off" />
                    <div class="invalid-feedback">
                        <span class="help-block" v-if="!$v.form_consumer.Lastname.required"><i class="fa fa-exclamation-circle"></i> Last name is required.</span>
                        <span class="help-block" v-if="!$v.form_consumer.Lastname.minLength"><i class="fa fa-exclamation-circle"></i> Last name must have at least {{$v.form_consumer.Lastname.$params.minLength.min}} characters.</span>
                        <span class="help-block" v-if="!$v.form_consumer.Lastname.maxLength"><i class="fa fa-exclamation-circle"></i> Last name must not exeed to {{$v.form_consumer.Lastname.$params.maxLength.max}} characters.</span>
                    </div>
                  </div>

                  <div class="form-group" :class="{'has-error':$v.form_consumer.Middlename.$error,'has-success':!$v.form_consumer.Middlename.$invalid }">
                    <label for="inputMiddlename">Middle Name</label><small class="text-info"> (optional)</small>
                    <input type="text" v-model.lazy="$v.form_consumer.Middlename.$model" :class="{'is-invalid':$v.form_consumer.Middlename.$error,'is-valid':!$v.form_consumer.Senior.$invalid }" class="form-control" id="inputMiddlename" aria-describedby="inputMiddlename" placeholder="Middle name" autocomplete="off" />
                    <div class="invalid-feedback">
                        <span class="help-block" v-if="!$v.form_consumer.Middlename.maxLength"><i class="fa fa-exclamation-circle"></i> Middle name must not exeed to {{$v.form_consumer.Senior.$params.maxLength.max}} characters.</span>
                    </div>
                  </div>

                  <div class="form-group" :class="{'has-error':$v.form_consumer.Extension.$error,'has-success':!$v.form_consumer.Extension.$invalid }">
                    <label for="inputExtension">Extension</label><small class="text-info"> (optional)</small>
                    <input type="text" v-model.lazy="$v.form_consumer.Extension.$model" :class="{'is-invalid':$v.form_consumer.Extension.$error,'is-valid':!$v.form_consumer.Extension.$invalid }" class="form-control" id="inputExtension" aria-describedby="inputExtension" placeholder="Extension name" autocomplete="off" />
                    <div class="invalid-feedback">
                        <span class="help-block" v-if="!$v.form_consumer.Extension.maxLength"><i class="fa fa-exclamation-circle"></i> Extension name must not exeed to {{$v.form_consumer.Extension.$params.maxLength.max}} characters.</span>
                    </div>
                  </div>

                  <div class="form-group" :class="{'has-error':$v.form_consumer.Address.$error,'has-success':!$v.form_consumer.Address.$invalid }">
                    <label for="inputAddress">Permanent Address</label><span class="text-danger">*</span>
                    <input type="text" v-model.lazy="$v.form_consumer.Address.$model" class="form-control" id="inputAddress" aria-describedby="inputAddress" :class="{'is-invalid':$v.form_consumer.Address.$error,'is-valid':!$v.form_consumer.Address.$invalid }" placeholder="Permanent Address" autocomplete="off" />
                    <div class="invalid-feedback">
                        <span class="help-block" v-if="!$v.form_consumer.Address.required"><i class="fa fa-exclamation-circle"></i> Address is required.</span>
                        <span class="help-block" v-if="!$v.form_consumer.Address.minLength"><i class="fa fa-exclamation-circle"></i> Address must have at least {{$v.form_consumer.Address.$params.minLength.min}} characters.</span>
                        <span class="help-block" v-if="!$v.form_consumer.Address.maxLength"><i class="fa fa-exclamation-circle"></i> Address must not exeed to {{$v.form_consumer.Address.$params.maxLength.max}} characters.</span>
                    </div>
                  </div>

                  <div class="form-group" :class="{'has-error':$v.form_consumer.Senior.$error,'has-success':!$v.form_consumer.Senior.$invalid }">
                    <label for="inputSenior">Senior Citizen ID</label><small class="text-info"> (optional)</small>
                    <input type="text" v-model.lazy="$v.form_consumer.Senior.$model" :class="{'is-invalid':$v.form_consumer.Senior.$error,'is-valid':!$v.form_consumer.Senior.$invalid }" class="form-control" id="inputSenior" aria-describedby="inputSenior" placeholder="Senior Citizen ID" autocomplete="off" />
                    <div class="invalid-feedback">
                        <span class="help-block" v-if="!$v.form_consumer.Senior.maxLength"><i class="fa fa-exclamation-circle"></i> Senior citizen ID must not exeed to {{$v.form_consumer.Senior.$params.maxLength.max}} characters.</span>
                    </div>
                  </div>

                  <div class="form-group" :class="{'has-error':$v.form_consumer.Pwd.$error,'has-success':!$v.form_consumer.Pwd.$invalid }">
                    <label for="inputPwd">PWD ID</label><small class="text-info"> (optional)</small>
                    <input type="text" v-model.lazy="$v.form_consumer.Pwd.$model" :class="{'is-invalid':$v.form_consumer.Pwd.$error,'is-valid':!$v.form_consumer.Pwd.$invalid }" class="form-control" id="inputPwd" aria-describedby="inputPwd" placeholder="PWD ID" autocomplete="off" />
                    <div class="invalid-feedback">
                        <span class="help-block" v-if="!$v.form_consumer.Pwd.maxLength"><i class="fa fa-exclamation-circle"></i> PWD ID must not exeed to {{$v.form_consumer.Pwd.$params.maxLength.max}} characters.</span>
                    </div>
                  </div>

                  <div class="form-group clearfix">
                    <div class="icheck-primary d-inline">
                      <input type="checkbox" id="checkboxPrimary1" v-model="confirm">
                      <label for="checkboxPrimary1">
                        Confirm to submit
                      </label>
                    </div>
                  </div>

                </div>
             
              </div>
            </div>
            <div class="card-footer">
              <div class="row col-12 p-0 m-0">
                  <div class="col-8 p-0">
                    
                  </div>
                  <div class="text-right col-12 p-0">
                    <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>&nbsp;
                    <button type="submit" :disabled="!confirm || $v.$invalid" class="btn btn-info" ><i class="fa fa-chevron-circle-right"></i> Submit</button>
                  </div>
              </div>
            </div>

            </form>


          </div>
        </div>
      </div>

      <!-- receipt modal -->
      <div class="modal fade" data-backdrop="static" id="receiptmodal" tabindex="-1" role="dialog" aria-labelledby="receiptModalLabel1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-xl" role="document" style="max-width: 950px !important;">
          <div class="modal-content">
            <div class="modal-header bg-orange">
              <h1 class="card-title modal-title"><b>OR No. {{ form_payment.or_no }}</b></h1>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form @keydown="form_payment.onKeydown($event)">
            <div class="modal-body">
              <div class="container-fluid bg-white">
                <div class="row">
                  <div class="col-sm-6 border-right">
                    <div class="card-body table-responsive p-0" id="printReceiptCopy">
                      <table class="table borderless col-12" >
                            <tbody>
                              <tr>
                                <td align="center" class="p-0"><img :src="require('@/assets/receipt-header-bw.png')" width="100%" /></td>
                              </tr>
                            </tbody>
                          </table>
                          <table class="table borderless col-12 mb-2">
                            <tbody>
                              <tr>
                                <td width="50%" align="left" class="p-0 pt-3"><h3><b><u>OR_COPY</u></b></h3></td>
                                <td width="50%" align="right" class="p-0"><h3><b>NO. {{ form_payment.or_no }}</b></h3>Date: <u>{{ form_payment.or_date | customdate2 }}</u></td>
                              </tr>
                              <tr> 
                                <td class="p-0" colspan="2">
                                  RECEIVED from <u>{{  getFullname() }}</u> address at <u>{{ consumer.Address }}</u> the sum of <u>{{ getTotalAmountPaid | toWordsPesos }} {{ getTotalAmountPaid | toWordsCentavos }}</u>
                                </td>
                              </tr>
                              <tr>
                                <td>In partial/full payment for:</td><td align="center">AMOUNT</td>
                              </tr>
                              <tr>
                                <td class="p-0">Membership Fee</td>
                                <td width="80%" class="p-0" style="border-bottom: 1px solid #000000 !important;" align="center">{{ form_payment.fee_membership }}</td>
                              </tr>
                              <tr>
                                <td class="p-0">Connection Fee</td>
                                <td width="80%" class="p-0" style="border-bottom: 1px solid #000000 !important;" align="center">{{ form_payment.fee_connection }}</td>
                              </tr>
                              <tr>
                                <td class="p-0">Reconnection Fee</td>
                                <td width="80%" class="p-0" style="border-bottom: 1px solid #000000 !important;" align="center">{{ form_payment.fee_reconnection }}</td>
                              </tr>
                              <tr>
                                <td class="p-0">Service Fee</td>
                                <td width="80%" class="p-0" style="border-bottom: 1px solid #000000 !important;" align="center">{{ form_payment.fee_service }}</td>
                              </tr>
                              <tr v-show="this.form_payment.trxns.length == 1">
                                <td class="p-0">Water Consumption Fee</td>
                                <td width="80%" class="p-0" style="border-bottom: 1px solid #000000 !important;" align="center" v-for="trxn in this.form_payment.trxns" :key="trxn.id">({{ trxn.reading_date | customdate7 }}) {{ xconvertFloat(trxn.amountdue) - xconvertFloat(trxn.surcharge) + xconvertFloat(trxn.discount)  | toMoney }}</td>
                              </tr>
                              <tr v-show="this.form_payment.trxns.length == 1">
                                <td class="p-0">Surcharge</td>
                                <td width="80%" class="p-0" style="border-bottom: 1px solid #000000 !important;" align="center" v-for="trxn in this.form_payment.trxns" :key="trxn.id">{{ trxn.surcharge }}</td>
                              </tr>
                              <tr>
                                <td class="p-0">Threading Fee</td>
                                <td width="80%" class="p-0" style="border-bottom: 1px solid #000000 !important;" align="center">{{ form_payment.fee_threading }}</td>
                              </tr>
                              <tr>
                                <td class="p-0">Water Meter</td>
                                <td width="80%" class="p-0" style="border-bottom: 1px solid #000000 !important;" align="center">{{ form_payment.fee_meter }}</td>
                              </tr>
                              <tr>
                                <td class="p-0">Materials</td>
                                <td width="80%" class="p-0" style="border-bottom: 1px solid #000000 !important;" align="center">{{ form_payment.fee_materials }}</td>
                              </tr>
                              <tr>
                                <td class="p-0">Others</td>
                                <td width="80%" class="p-0" style="border-bottom: 1px solid #000000 !important;" align="center">{{ form_payment.fee_others }}</td>
                              </tr>
                              <tr v-show="this.form_payment.trxns.length == 1">
                                <td class="p-0">SC/PWD Discount</td>
                                <td width="80%" class="p-0" style="border-bottom: 1px solid #000000 !important;" align="center" v-for="trxn in this.form_payment.trxns" :key="trxn.id">{{ trxn.discount }}</td>
                              </tr>
                              <tr >
                                <td class="p-0"><br/>Total Amount Paid</td>
                                <td width="80%" class="p-0" style="border-bottom: 1px solid #000000 !important;" align="center"><br/><b>{{ getTotalAmountPaid | toMoney }}</b></td>
                              </tr>
                            </tbody>
                          </table>

                          <table v-show="this.form_payment.trxns.length != 1" class="bordered col-12" style="width:100%;border-spacing: 0;">
                            <thead>
                              <tr>
                                <!--<td style="border: 1px solid #989898 !important;"><b>Id</b></td>-->
                                <td style="border: 1px solid #989898 !important;"><b>Reading</b></td>
                                <td style="border: 1px solid #989898 !important;"><b>Prev.</b></td>
                                <td style="border: 1px solid #989898 !important;"><b>Pres.</b></td>
                                <td style="border: 1px solid #989898 !important;"><b>Con.</b></td>
                                <td style="border: 1px solid #989898 !important;"><b>Discount</b></td>
                                <td style="border: 1px solid #989898 !important;"><b>Surcharge</b></td>
                                <td style="border: 1px solid #989898 !important;"><b>Amt.</b></td>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="trxn in this.form_payment.trxns" :key="trxn.id">
                                <!--<td style="border: 1px solid #989898 !important;">{{ trxn.id }}</td>-->
                                <td style="border: 1px solid #989898 !important;">{{ trxn.reading_date | customdate2}}</td>
                                <td style="border: 1px solid #989898 !important;">{{ trxn.pres_reading }}</td>
                                <td style="border: 1px solid #989898 !important;">{{ trxn.prev_reading }}</td>
                                <td style="border: 1px solid #989898 !important;">{{ trxn.consumption }}</td>
                                <td style="border: 1px solid #989898 !important;">{{ trxn.discount }}</td>
                                <td style="border: 1px solid #989898 !important;">{{ trxn.surcharge }}</td>
                                <td style="border: 1px solid #989898 !important;">{{ trxn.amountdue }}</td>
                              </tr>
                            </tbody>
                          </table>


                          <table v-show="this.form_payment.trxns.length == 1" class="table bordered p-0" style="width:75%;border-spacing: 0;">
                            <tbody v-for="trxn in this.form_payment.trxns" :key="trxn.id">
                              <tr>
                                <td align="left" class="p-0  border border-dark"><b>Present Reading</b><span class="float-right">({{ trxn.reading_date | customdate2 }}) {{ trxn.pres_reading }}</span></td>
                              </tr>
                              <tr>
                                <td align="left" class="p-0 border border-dark"><b>Previous Reading</b><span class="float-right">{{ trxn.prev_reading }}</span></td>
                              </tr>
                              <tr>
                                <td align="left" class="p-0 border border-dark"><b>Water Consumed</b><span class="float-right">{{ trxn.consumption }}</span></td>
                              </tr>
                              <tr>
                                <td align="left" class="p-0 border border-dark"><small>Form of Payment:</small></td>
                              </tr>
                              <tr>
                                <td align="left" class="p-0 border border-dark"><small>Cash</small>&nbsp;&#9645;&nbsp;&nbsp;<small>Check</small>&nbsp;&#9645;</td>
                              </tr>
                            </tbody>
                          </table>
                              

                          <table class="table col-12" style="width:100%;margin: 0 auto;">
                            <tr>
                              <td width="50%" class="p-0" style="border-top: 1px solid #FFFFFF;">
                              <table class="table bordered p-0" style="width:220px;border-spacing: 0;">
                                <tr>
                                  <td colspan="2" align="left" class="p-1  border border-dark"><small><b>Sr.Citizen TIN:</b></small><br/>{{ form_payment.senior_no }}&nbsp;</td>
                                </tr>
                                <tr>
                                  <td align="left" class="p-1 border border-dark"><small><b>OSCA/PWD ID No.</b></small><br/>{{ form_payment.pwd_no }}&nbsp;</td>
                                  <td align="left" class="p-1 border border-dark"><small><b>Signature:</b></small><br/>&nbsp;</td>
                                </tr>
                              </table>
                              </td>
                              <td width="50%" align="center" valign="center" style="border-top: 1px solid #FFFFFF;">
                                <br/><br/>
                                By: <u>&nbsp;{{ form_payment.cashier }}&nbsp;</u><br/>
                                <small>Cashier/Authorized Representative</small>
                              </td>
                            </tr>
                          </table>
                          <br/>
                          <span><b>This is a receipt copy only.</b></span>
                          <!--
                          <table class="table borderless col-12" style="width:100%;margin: 0 auto;">
                            <tr>
                              <td align="center" class="p-0"><img :src="require('@/assets/receipt-footer.png')" width="100%" /></td>
                            </tr>
                          </table>
                          -->
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="card-body table-responsive p-0" id="printReceiptOrig" style="height:950px !important">
                      <span class="receiptfont" style="position: absolute;top:103px;left:230px;font-family: monospace;font-size: 18px;"><b>{{ form_payment.or_date | customdate2 }}</b></span>                      
                      <table class="table borderless col-12" width="350px" style="margin-top: 123px">
                        <tbody>
                          <tr>
                            <td align="left" class="p-0" style="font-family: monospace;font-size: 18px;"><span style="margin-left:142px;"><b>{{ getFullname() | bigcaps | trimright(20)}}</b></span></td>
                          </tr>
                        </tbody>
                      </table>
                      <table class="table borderless col-12" width="350px" style="margin-top: -7px">
                        <tbody>
                          <tr>
                            <td align="left" class="p-0" style="font-family: monospace;font-size: 18px;"><span v-if="consumer.Fullname && consumer.Fullname.length > 21" style="margin-left:-7px;"><b>{{ getFullname() | bigcaps | trimleft(20) | trimright(35) }}</b></span><span v-else style="margin-left:-7px;"><b>&nbsp;</b></span></td>
                          </tr>
                        </tbody>
                      </table>
                      <table class="table borderless col-12" width="350px" style="margin-top: -5px">
                        <tbody>
                          <tr>
                            <td align="left" class="p-0" style="font-family: monospace;font-size: 18px;"><b><span style="margin-left:61px;">{{ consumer.Address | bigcaps | trimright(28) }}</span></b></td>
                          </tr>
                        </tbody>
                      </table>
                      <table class="table borderless col-12" width="350px" style="margin-top: -2px">
                        <tbody>
                          <tr>
                            <td align="left" class="p-0" style="font-family: monospace;font-size: 15px;"><span style="margin-left:61px;"><b>{{ getTotalAmountPaid | moneyToWordsPesos | smallcaps | trimright(28) }}</b></span></td>
                          </tr>
                        </tbody>
                      </table>
                      <table class="table borderless col-12" width="350px" style="margin-top: -2px">
                        <tbody>
                          <tr>
                            <td align="left" class="p-0" style="font-family: monospace;font-size: 15px;"><span style="margin-left:-7px;"><b>{{ getTotalAmountPaid | moneyToWordsPesos | trimleft(28) | trimright(35) | allsmallcaps}}</b></span></td>
                          </tr>
                        </tbody>
                      </table>
                      <table class="table borderless col-12" width="350px" style="margin-top: -4px">
                        <tbody>
                          <tr>
                            <td align="left" class="p-0" style="font-family: monospace;font-size: 15px;"><span v-if="this.totalamtpd.length > 63" style="margin-left:-7px;"><b>{{ getTotalAmountPaid | moneyToWordsPesos | trimleft(63) | trimright(35) | allsmallcaps }}</b></span><span v-else style="margin-left:-7px;"><b>&nbsp;</b></span></td>
                          </tr>
                        </tbody>
                      </table>
                      <table class="table borderless col-12" width="350px" style="margin-top: 25px">
                        <tbody>
                          <tr>
                            <td align="right" class="p-0 mr-2" style="font-family: monospace;font-size: 15px;"><span style="margin-right:10px;"><b>{{ form_payment.fee_membership }}&nbsp;&nbsp;&nbsp;&nbsp;</b></span></td>
                          </tr>
                        </tbody>
                      </table>
                      <table class="table borderless col-12" width="350px" style="margin-top: -7px">
                        <tbody>
                          <tr>
                            <td align="right" class="p-0" style="font-family: monospace;font-size: 15px;"><span style="margin-right:10px;"><b>{{ form_payment.fee_connection }}&nbsp;&nbsp;&nbsp;&nbsp;</b></span></td>
                          </tr>
                        </tbody>
                      </table>
                      <table class="table borderless col-12" width="350px" style="margin-top: -7px">
                        <tbody>
                          <tr>
                            <td align="right" class="p-0" style="font-family: monospace;font-size: 15px;"><span style="margin-right:10px;"><b>{{ form_payment.fee_reconnection }}&nbsp;&nbsp;&nbsp;&nbsp;</b></span></td>
                          </tr>
                        </tbody>
                      </table>
                      <table class="table borderless col-12" width="350px" style="margin-top: -8px">
                        <tbody>
                          <tr>
                            <td align="right" class="p-0" style="font-family: monospace;font-size: 15px;"><span style="margin-right:10px;"><b>{{ form_payment.fee_service }}&nbsp;&nbsp;&nbsp;&nbsp;</b></span></td>
                          </tr>
                        </tbody>
                      </table>
                      <table class="table borderless col-12" width="350px" style="margin-top: -7px">
                        <tbody>
                          <tr v-if="this.form_payment.trxns.length != 1">
                            <td align="right" class="p-0" style="font-family: monospace;font-size: 15px;"><span style="margin-right:10px;"><b>0.00&nbsp;&nbsp;&nbsp;&nbsp;</b></span></td>
                          </tr>
                          <tr v-else>
                            <td align="right" class="p-0" style="font-family: monospace;font-size: 15px;" v-for="trxn1 in this.form_payment.trxns" :key="trxn1.id"><span style="margin-right:10px;"><b>({{ trxn1.reading_date | customdate7 }}) {{ xconvertFloat(trxn1.amountdue) - xconvertFloat(trxn1.surcharge) + xconvertFloat(trxn1.discount)  | toMoney }}&nbsp;&nbsp;&nbsp;&nbsp;</b></span></td>
                          </tr>
                        </tbody>
                      </table>
                      <table class="table borderless col-12" width="350px" style="margin-top: -7px">
                        <tbody>
                          <tr v-if="this.form_payment.trxns.length != 1">
                            <td align="right" class="p-0" style="font-family: monospace;font-size: 15px;"><span style="margin-right:10px;"><b>0.00&nbsp;&nbsp;&nbsp;&nbsp;</b></span></td>
                          </tr>
                          <tr v-else>
                            <td align="right" class="p-0" style="font-family: monospace;font-size: 15px;" v-for="trxn2 in this.form_payment.trxns" :key="trxn2.id"><span style="margin-right:10px;"><b>{{ trxn2.surcharge }}&nbsp;&nbsp;&nbsp;&nbsp;</b></span></td>
                          </tr>
                        </tbody>
                      </table>
                      <table class="table borderless col-12" width="350px" style="margin-top: -8px">
                        <tbody>
                          <tr>
                            <td align="right" class="p-0" style="font-family: monospace;font-size: 15px;"><span style="margin-right:10px;"><b>{{ form_payment.fee_threading }}&nbsp;&nbsp;&nbsp;&nbsp;</b></span></td>
                          </tr>
                        </tbody>
                      </table>
                      <table class="table borderless col-12" width="350px" style="margin-top: -7px">
                        <tbody>
                          <tr>
                            <td align="right" class="p-0" style="font-family: monospace;font-size: 15px;"><span style="margin-right:10px;"><b>{{ form_payment.fee_meter }}&nbsp;&nbsp;&nbsp;&nbsp;</b></span></td>
                          </tr>
                        </tbody>
                      </table>
                      <table class="table borderless col-12" width="350px" style="margin-top: -8px">
                        <tbody>
                          <tr>
                            <td align="right" class="p-0" style="font-family: monospace;font-size: 15px;"><span style="margin-right:10px;"><b>{{ form_payment.fee_materials }}&nbsp;&nbsp;&nbsp;&nbsp;</b></span></td>
                          </tr>
                        </tbody>
                      </table>
                      <table class="table borderless col-12" width="350px" style="margin-top: -7px">
                        <tbody>
                          <tr>
                            <td align="right" class="p-0" style="font-family: monospace;font-size: 15px;"><span style="margin-right:10px;"><b>{{ form_payment.fee_others }}&nbsp;&nbsp;&nbsp;&nbsp;</b></span></td>
                          </tr>
                        </tbody>
                      </table>
                      <table class="table borderless col-12" width="350px" style="margin-top: -8px">
                        <tbody>
                          <tr v-if="this.form_payment.trxns.length != 1">
                            <td align="right" class="p-0" style="font-family: monospace;font-size: 15px;"><span style="margin-right:10px;"><b>0.00&nbsp;&nbsp;&nbsp;&nbsp;</b></span></td>
                          </tr>
                          <tr v-else v-for="trxn3 in this.form_payment.trxns" :key="trxn3.id">
                            <td align="right" class="p-0" style="font-family: monospace;font-size: 15px;"><span style="margin-right:10px;"><b>{{ trxn3.discount }}&nbsp;&nbsp;&nbsp;&nbsp;</b></span></td>
                          </tr>
                        </tbody>
                      </table>
                      <table class="table borderless col-12" width="350px" style="margin-top: 7px">
                        <tbody>
                          <tr>
                            <td align="right" class="p-0" style="font-family: monospace;font-size: 18px;"><span style="margin-right:10px;"><b>{{ getTotalAmountPaid | toMoney }}&nbsp;&nbsp;&nbsp;</b></span></td>
                          </tr>
                        </tbody>
                      </table>

                      <table v-show="this.form_payment.trxns.length != 1" class="bordered col-12" width="350px" style="border-spacing: 0;position:absolute;top:498px;">
                        <thead>
                          <tr>
                            <!--<td style="border: 1px solid #989898 !important;"><b>Id</b></td>-->
                            <td style="border: 1px solid #989898 !important;font-family: 'saxMono', Arial;font-size:12px;"><b>Reading</b></td>
                            <td style="border: 1px solid #989898 !important;font-family: 'saxMono', Arial;font-size:12px;"><b>Prev.</b></td>
                            <td style="border: 1px solid #989898 !important;font-family: 'saxMono', Arial;font-size:12px;"><b>Pres.</b></td>
                            <td style="border: 1px solid #989898 !important;font-family: 'saxMono', Arial;font-size:12px;"><b>Con.</b></td>
                            <td style="border: 1px solid #989898 !important;font-family: 'saxMono', Arial;font-size:12px;"><b>Discount</b></td>
                            <td style="border: 1px solid #989898 !important;font-family: 'saxMono', Arial;font-size:12px;"><b>Surcharge</b></td>
                            <td style="border: 1px solid #989898 !important;font-family: 'saxMono', Arial;font-size:12px;"><b>Amt.</b></td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="trxn in this.form_payment.trxns" :key="trxn.id">
                            <!--<td style="border: 1px solid #989898 !important;">{{ trxn.id }}</td>-->
                            <td style="border: 1px solid #989898 !important;font-family: 'saxMono', Arial;font-size:12px;"><b>{{ trxn.reading_date | customdate2}}</b></td>
                            <td style="border: 1px solid #989898 !important;font-family: 'saxMono', Arial;font-size:12px;"><b>{{ trxn.pres_reading }}</b></td>
                            <td style="border: 1px solid #989898 !important;font-family: 'saxMono', Arial;font-size:12px;"><b>{{ trxn.prev_reading }}</b></td>
                            <td style="border: 1px solid #989898 !important;font-family: 'saxMono', Arial;font-size:12px;"><b>{{ trxn.consumption }}</b></td>
                            <td style="border: 1px solid #989898 !important;font-family: 'saxMono', Arial;font-size:12px;"><b>{{ trxn.discount }}</b></td>
                            <td style="border: 1px solid #989898 !important;font-family: 'saxMono', Arial;font-size:12px;"><b>{{ trxn.surcharge }}</b></td>
                            <td style="border: 1px solid #989898 !important;font-family: 'saxMono', Arial;font-size:12px;"><b>{{ trxn.amountdue }}</b></td>
                          </tr>
                        </tbody>
                      </table>
                      <!--
                      <table class="table borderless col-12" >
                        <tbody>
                          <tr>
                            <td align="left" class="p-0">{{ form_payment.senior_no }}</td>
                          </tr>
                          <tr>
                            <td align="left" class="p-0">{{ form_payment.pwd_no }}</td>
                          </tr>
                          <tr>
                            <td align="right" class="p-0">{{ form_payment.cashier }}</td>
                          </tr>
                        </tbody>
                      </table>
                      -->
                      <div v-show="this.form_payment.trxns.length == 1">
                      <span v-for="trxn4 in this.form_payment.trxns" :key="trxn4.id">
                        <span style="position: absolute;top:516px;left:120px;font-family: monospace;"><b>{{ trxn4.pres_reading }}</b>({{ trxn4.reading_date | customdate2 }})</span>
                        <span style="position: absolute;top:533px;left:120px;font-family: monospace;"><b>{{ trxn4.prev_reading }}</b></span>
                        <span style="position: absolute;top:550px;left:120px;font-family: monospace;"><b>{{ trxn4.consumption }}</b></span>
                      </span>
                      </div>

                      <span style="position: absolute;top:620px;left:10px;font-family: monospace;"><b>{{ form_payment.senior_no  | bigcaps }}</b></span>
                      <span style="position: absolute;top:650px;left:10px;font-family: monospace;"><b>{{ form_payment.pwd_no  | bigcaps }}</b></span>
                      <span style="position: absolute;top:620px;left:185px;width:300px;font-family: monospace;"><b>{{ form_payment.cashier | bigcaps | truncate(20, '') }}</b></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="row col-12 p-0 m-0">
                  <div class="col-4 p-0">
                    <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>&nbsp;
                  </div>
                  <div class="text-right col-8 p-0">
                    <button type="button" onclick="printJS({
                      printable: 'printReceiptCopy', 
                      type:'html',
                      style: 'html {transform: scale(.7);transform-origin: left; .small {font-size: 5px !important;}} body{position:absolute!important;top:0!important;left:0!important;} #printReceiptCopy {position:absolute!important;top:0!important;left:0!important;overflow-y:visible!important;overflow-x:visible !important;} #printReceiptCopy::-webkit-scrollbar {display: none;}',
                      targetStyles: ['*']
                      })" class="btn btn-success"><i class="fa fa-print mr-1"></i> Print Copy</button>&nbsp;
                    <!--
                    <button type="button" onclick="printJS({
                      printable: 'printReceiptOrig', 
                      type:'html',
                      style: 'html {transform: scale(.7);transform-origin: left; .small {font-size: 5px !important;}} body{position:absolute!important;top:0!important;left:0!important;} #printReceiptOrig {position:absolute!important;top:0!important;left:0!important;overflow-y:visible!important;overflow-x:visible !important;} #printReceiptOrig::-webkit-scrollbar {display: none;}',
                      targetStyles: ['*']
                      })" class="btn btn-success"><i class="fa fa-print mr-1"></i> Print OR</button>
                    -->
                    <button type="button" class="btn btn-success ml-1 mr-1" @click="printOR();"><i class="fa fa-print mr-1"></i>  Print OR</button>  
                  </div>
              </div>
            </div>
            </form>

          </div>
        </div>
      </div>

    <!-- Modals -->

    
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-8">
            <h1 class="m-0">
                <router-link :to="{ path: '/consumers'}" class="btn btn-info"><i class="fa fa-mail-reply"></i> BACK</router-link>
                &nbsp;<b>{{ consumer.Consumercode }}</b> &#8231; {{ consumer.Consumertype }} &#8231; {{ consumer.Pipe }} Pipe
            </h1>
          </div><!-- /.col -->
          <div class="col-sm-4">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">Home</li>
              <li class="breadcrumb-item">Consumers</li>
              <li class="breadcrumb-item active">Account</li>
            </ol>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <!-- /.content-header -->

    <!-- Main content -->
    <div class="content">
      <div class="container-fluid">
        <div class="row d-print-none">
          <!-- /.col-md-6 -->
          <div class="col-lg-6">
            <div class="card">
              <Loader v-if="loading" style="height: 100%" />
              <div class="card-header">
                <h1 class="card-title">Balance Details</h1>
              </div>
              <div v-if="loading" class="card-body p-0">
                <div class="row justify-content-center mt-5">
                    <svg width="64" height="41" viewBox="0 0 64 41" xmlns="http://www.w3.org/2000/svg">
                      <g transform="translate(0 1)" fill="none" fill-rule="evenodd">
                        <ellipse fill="#F5F5F5" cx="32" cy="33" rx="32" ry="7"></ellipse>
                        <g fill-rule="nonzero" stroke="#D9D9D9">
                          <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
                          <path d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z" fill="#FAFAFA"></path>
                        </g>
                      </g>
                    </svg>                  
                </div>
                <div class="row justify-content-center mb-5">
                  <p>Loading</p>
                </div>
              </div>
              <div v-else class="card-body">
                <div>
                  <div v-if="readings.total==0" class="card-body p-0">
                    <div class="row justify-content-center mt-5">
                        <svg width="64" height="41" viewBox="0 0 64 41" xmlns="http://www.w3.org/2000/svg">
                          <g transform="translate(0 1)" fill="none" fill-rule="evenodd">
                            <ellipse fill="#F5F5F5" cx="32" cy="33" rx="32" ry="7"></ellipse>
                            <g fill-rule="nonzero" stroke="#D9D9D9">
                              <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
                              <path d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z" fill="#FAFAFA"></path>
                            </g>
                          </g>
                        </svg>                  
                    </div>
                    <div class="row justify-content-center text-muted">
                      <p>No records found</p>
                    </div>
                  </div>
                  <div v-else v-for="(reading, index) in readings.data" :key="index">
                    <div class="row" v-if="index==0">
                      <div class="col-sm" v-if="reading.active==1">
                          <h6><b>Reading Date</b> : {{ reading.created_at | customdate }}</h6>
                          <h6><b>Bill Due Date</b> : {{ reading.due_at | customdate }}</h6>
                      </div>
                      <div class="col-sm" v-else-if="reading.active==2">
                          <h6><b class="text-primary"><i class="fa fa-exclamation-circle" aria-hidden="true"></i> New water meter installed</b></h6>
                          <h6><b class="text-primary"><i class="fa fa-exclamation-circle" aria-hidden="true"></i> Present reading is reset to zero</b></h6>
                      </div>
                      <div class="col-sm" v-else>
                          <h6><b class="text-danger"><i class="fa fa-exclamation-circle" aria-hidden="true"></i> Service is Disconnected</b></h6>
                          <h6><b class="text-danger"><i class="fa fa-exclamation-circle" aria-hidden="true"></i> Charges are excempted</b></h6>
                      </div>

                      <div class="col-sm" v-if="reading.active==1">
                          <h6><b>Current Charges</b> : ₱ {{ reading.currentcharge | toMoney }}</h6>
                          <h6 class="mb-0"><b>Discount</b> : ₱ {{ computeDiscount(reading.discount, reading.currentcharge, consumer.consumertype) | toMoney }}</h6>
                          <small style="margin-top: -5px;"><i>(Discount {{reading.discount}}% capped at ₱23)</i></small>
                      </div>
                      <div class="col-sm" v-else-if="reading.active==2">
                          <h6><b class="text-primary"><i class="fa fa-hand-o-right" aria-hidden="true"></i> Please proceed to new reading</b></h6>
                      </div>
                      <div class="col-sm" v-else>
                          <h6><b class="text-danger"><i class="fa fa-hand-o-right" aria-hidden="true"></i> Activate and proceed reading</b></h6>
                      </div>
                      <span v-show="reading.active==1">
                      <div v-if="!reading.payments" class="callout callout-warning text-danger">
                        <h5>Total Amount Due</h5>
                        <h5><b>₱ {{ reading.amountdue | toMoney }}</b></h5>
                      </div>
                      <div v-else class="callout callout-warning text-success">
                        <h5>Total Amount Due</h5>
                        <h5><b>₱ {{ reading.amountdue | toMoney }}</b></h5>
                      </div>
                      </span>

                      <button v-if="reading.transactions.length" type="button" class="btn btn-block btn-success btn-lg btn-lg mt-2" disabled><b><i class="fa fa-check mr-2"></i> PAID</b></button>
                      <button v-else :disabled="reading.amountdue==0.00" v-show="user.role === 'Cashier' || user.role === 'Supervisor' || user.role === 'Admin'" type="button" @click="newPayment(reading)" class="btn btn-block btn-primary btn-lg btn-lg mt-2"><b><i class="fa fa-money mr-2"></i> PAY NOW</b></button>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <!-- /.col-md-6 -->
          <!-- /.col-md-6 -->
          <div class="col-lg-6">
            <div class="card">
              <Loader v-if="loading" style="height: 100%" />
              <Loader v-else-if="loading_consumer" style="height: 260px" />
              <div class="card-header">
                <h1 class="card-title">Account Information</h1> <button type="button" class="btn btn-tool" @click="editConsumer(consumer)">
                    <i class="fa fa-edit"></i> Edit
                  </button>
                <div class="card-tools">
                  
                  <div class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success" >
                          <input type="checkbox" class="custom-control-input" @click="setServiceStatus(consumer.id, consumer.Active, $event)" v-bind:id="'StatusSwitch_' + consumer.id" :checked="consumer.Active"  />
                          <label class="custom-control-label" v-bind:for="'StatusSwitch_' + consumer.id">
                              <span v-if="consumer.Active == 1">Connected</span>
                              <span v-else>Disconnected</span>
                          </label>
                        </div>
                </div>
              </div>
              <div v-if="loading" class="card-body p-0">
                <div class="row justify-content-center mt-5">
                    <svg width="64" height="41" viewBox="0 0 64 41" xmlns="http://www.w3.org/2000/svg">
                      <g transform="translate(0 1)" fill="none" fill-rule="evenodd">
                        <ellipse fill="#F5F5F5" cx="32" cy="33" rx="32" ry="7"></ellipse>
                        <g fill-rule="nonzero" stroke="#D9D9D9">
                          <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
                          <path d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z" fill="#FAFAFA"></path>
                        </g>
                      </g>
                    </svg>                  
                </div>
                <div class="row justify-content-center mb-5">
                  <p>Loading</p>
                </div>
              </div>
              <div v-else class="card-body">
                <div class="row">
                    <div class="col-sm"> 
                        <h6><b>Account Number</b> : {{ consumer.Consumercode }}</h6>
                        <h6>
                          <b>Client</b> : 
                          <span v-if="getFullname().length < 25">
                            {{ getFullname() }}
                          </span>
                          <span v-else v-tooltip="{ content: '<b>' + getFullname() + '</b>', placement: 'top', classes: ['info'], targetClasses: ['it-has-a-tooltip'], offset: 5, delay: { show: 500, hide: 300, }, }">
                            {{ getFullname() | truncate(25, '...') }}
                          </span>
                        </h6>
                                                  <!--
                            <h6><b>Previous Charges</b> : 0.00 <i class="fa fa-question-circle cursor" ></i></h6>
                            -->
                        <h6><b>Type</b> : {{ consumer.Consumertype }} ({{ consumer.Pipe }} Pipe)</h6>
                        <h6><b>Service Location</b> : {{ consumer.Location }}</h6>
                    </div>
                    <div class="col-sm">
                        <h6><b>Connection Status</b> :
                        <span v-if="consumer.Active == 1" class="right badge badge-success">Service is connected</span>
                        <span v-else class="right badge badge-danger">Service is disconnected</span>
                        </h6>
                        <h6><b>Senior Citizen ID</b> : {{ consumer.Senior }}</h6>
                        <h6><b>PWD ID</b> : {{ consumer.Pwd }}</h6>
                        <h6>
                          <b>Address</b> : 
                          <span v-if="consumer.Address && consumer.Address.length < 30">
                            {{ consumer.Address }}
                          </span>
                          <span v-else v-tooltip="{ content: '<b>' + consumer.Address + '</b>', placement: 'top', classes: ['info'], targetClasses: ['it-has-a-tooltip'], offset: 5, delay: { show: 500, hide: 300, }, }">
                            {{ consumer.Address | truncate(30, '...') }}
                          </span>
                        </h6>
                    </div>
                    <button v-show="user.role === 'Reader' || user.role === 'Admin' || user.role === 'Supervisor'" type="button" @click="newReading" class="btn btn-block btn-info btn-lg mt-2"><b><i class="fa fa-tachometer mr-2"></i> READING</b></button>
                </div>
              </div>
            </div>
          </div>
          <!-- /.col-md-6 -->
        </div>
        <!-- /.row -->
        <div class="row">
          <!-- /.col-md-6 -->
          <div class="col-lg-6">
            <div class="card">
              <Loader v-if="loading"  style="height: 100%" />
              <div class="card-header">
                  <h1 class="card-title mt-1">Billing Statements</h1>
                  <div class="card-tools">
                    <div class="input-group mb-0">
                      <button type="button" @click="resetMeter" class="btn btn-danger btn-sm mr-2" v-show="user.role === 'Admin' || user.role === 'Supervisor'">
                        <i class="fa fa-exclamation-triangle"></i> &nbsp;Reset Meter
                      </button>
                      <div class="mr-0">
                        <button title="Search By" type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                          {{this.reading_year}}
                        </button>
                        <ul class="dropdown-menu" x-placement="bottom-start" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 48px, 0px);">
                          <li v-for="year in years" :value="year" :key="year" class="dropdown-item ddown" @click="loadDataReading(year)">{{ year }}</li>
                        </ul>
                      </div>
                  </div>
                 </div>
              </div>
              <div v-if="loading" class="card-body p-0">
                <div class="row justify-content-center mt-5">
                    <svg width="64" height="41" viewBox="0 0 64 41" xmlns="http://www.w3.org/2000/svg">
                      <g transform="translate(0 1)" fill="none" fill-rule="evenodd">
                        <ellipse fill="#F5F5F5" cx="32" cy="33" rx="32" ry="7"></ellipse>
                        <g fill-rule="nonzero" stroke="#D9D9D9">
                          <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
                          <path d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z" fill="#FAFAFA"></path>
                        </g>
                      </g>
                    </svg>                  
                </div>
                <div class="row justify-content-center mb-5">
                  <p>Loading</p>
                </div>
              </div>
              <div v-else class="card-body p-0">
                <table class="table table-striped table-hover">
                  <div v-if="readings.total==0" class="card-body p-0">
                    <div class="row justify-content-center mt-5">
                        <svg width="64" height="41" viewBox="0 0 64 41" xmlns="http://www.w3.org/2000/svg">
                          <g transform="translate(0 1)" fill="none" fill-rule="evenodd">
                            <ellipse fill="#F5F5F5" cx="32" cy="33" rx="32" ry="7"></ellipse>
                            <g fill-rule="nonzero" stroke="#D9D9D9">
                              <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
                              <path d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z" fill="#FAFAFA"></path>
                            </g>
                          </g>
                        </svg>                  
                    </div>
                    <div class="row justify-content-center mb-5 text-muted">
                      <p>No records found</p>
                    </div>
                  </div>
                  <tbody>
                    <tr v-for="reading in readings.data" :key="reading.id">
                      <td class="text-left">
                        <img v-if="!reading.transactions.length && reading.active==1" width="55px" :src="require('@/assets/stamp-pending.png')" alt="" class="brand-image" />
                        <img v-else-if="!reading.transactions.length && reading.active==0" width="55px" :src="require('@/assets/stamp-disconnected.png')" alt="" class="brand-image" />
                        <img v-else-if="!reading.transactions.length && reading.active==2" width="55px" :src="require('@/assets/stamp-reset.png')" alt="" class="brand-image" />
                        <img v-else width="55px" :src="require('@/assets/stamp-paid.png')" alt="" class="brand-image" />
                      </td>
                      <td class="text-left">
                        <b>{{ reading.created_at | moment("YYYY-MMM-D") }}</b><br/>
                        <span v-if="reading.active==0" class="right badge badge-danger">D</span><span v-else-if="reading.active==2" class="right badge badge-primary">R</span><span v-else class="right badge badge-success">C</span> <small>{{ reading.consumption  }} cubic m.</small>
                      </td>
                      <td class="text-right">
                        <h2 v-if="!reading.transactions.length && reading.active==1" class="text-danger">₱{{ reading.amountdue | toMoney }}</h2>
                        <h2 v-else-if="reading.active==0" class="text-secondary">₱{{ reading.amountdue | toMoney }}</h2>
                        <h2 v-else-if="reading.active==2" class="text-primary">Reset</h2>
                        <h2 v-else class="text-success">₱{{ reading.amountdue | toMoney }}</h2>
                      </td>
                      <td class="text-right">
                        <!--
                        <button title="View receipt" type="button" v-show="reading.transactions.length" @click="viewPayment(reading)" class="btn btn-success btn-sm mt-2 ml-2" v-tooltip="{ content: '<b>Show Receipt</b>', placement: 'top', classes: ['info'], targetClasses: ['it-has-a-tooltip'], offset: 10, delay: { show: 500, hide: 300, }, }"><i class="fa fa-qrcode"></i></button>
                        -->
                        <button title="Pay this billing" type="button" :disabled="user.role === 'Reader' || user.role === 'Staff'" v-show="!reading.transactions.length && reading.active=='1'"  @click="newPayment(reading)" class="btn btn-primary btn-sm mt-2" v-tooltip="{ content: '<b>Pay this billing</b>', placement: 'top', classes: ['info'], targetClasses: ['it-has-a-tooltip'], offset: 10, delay: { show: 500, hide: 300, }, }"><i class="fa fa-money"></i></button>
                        <!--<button title="Edit Reading" type="button" @click="editReading(reading)" class="btn btn-info btn-sm mt-2 ml-2" v-tooltip="{ content: '<b>Edit Reading</b>', placement: 'top', classes: ['info'], targetClasses: ['it-has-a-tooltip'], offset: 10, delay: { show: 500, hide: 300, }, }"><i class="fa fa-edit"></i></button> -->
                        <button title="View billing statement" type="button" v-if="reading.active==1" @click="viewReading(reading)" class="btn btn-info btn-sm mt-2 ml-2" v-tooltip="{ content: '<b>Show Billing Statement</b>', placement: 'top', classes: ['info'], targetClasses: ['it-has-a-tooltip'], offset: 10, delay: { show: 500, hide: 300, }, }"><i class="fa fa-folder-open"></i></button>
                      </td>
                      
                    </tr>
                  </tbody>
                </table>
              </div>
              <!--
              <div class="card-footer border-top">
               <pagination class="pagination justify-content-right m-0" :data="readings" @pagination-change-page="getResults" show-disabled :limit="2"></pagination>
              </div>
              -->
            </div>
          </div>
          <!-- /.col-md-6 -->
          <!-- /.col-md-6 -->
          <div class="col-lg-6">
            <div class="card">
              <Loader v-if="loading"  style="height: 100%" />
              <div class="card-header">
                <h1 class="card-title mt-1">Transaction History</h1>
                <div class="card-tools">
                  <div class="input-group mb-0">
                    <div class="mr-0">
                      <button title="Search By" type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                        {{this.transaction_year}}
                      </button>
                      <ul class="dropdown-menu" x-placement="bottom-start" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 48px, 0px);">
                        <li v-for="year in years" :value="year" :key="year" class="dropdown-item ddown" @click="loadDataTransaction(year)">{{ year }}</li>
                      </ul>
                    </div>
                  </div>
                </div>  
              </div>
              <div v-if="loading" class="card-body p-0">
                <div class="row justify-content-center mt-5">
                    <svg width="64" height="41" viewBox="0 0 64 41" xmlns="http://www.w3.org/2000/svg">
                      <g transform="translate(0 1)" fill="none" fill-rule="evenodd">
                        <ellipse fill="#F5F5F5" cx="32" cy="33" rx="32" ry="7"></ellipse>
                        <g fill-rule="nonzero" stroke="#D9D9D9">
                          <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
                          <path d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z" fill="#FAFAFA"></path>
                        </g>
                      </g>
                    </svg>                  
                </div>
                <div class="row justify-content-center mb-5">
                  <p>Loading</p>
                </div>
              </div>
              <div v-else class="card-body p-0">
                <table class="table table-striped table-hover"> 
                  <div v-if="transactions.total==0" class="card-body p-0">
                    <div class="row justify-content-center mt-5">
                        <svg width="64" height="41" viewBox="0 0 64 41" xmlns="http://www.w3.org/2000/svg">
                          <g transform="translate(0 1)" fill="none" fill-rule="evenodd">
                            <ellipse fill="#F5F5F5" cx="32" cy="33" rx="32" ry="7"></ellipse>
                            <g fill-rule="nonzero" stroke="#D9D9D9">
                              <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
                              <path d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z" fill="#FAFAFA"></path>
                            </g>
                          </g>
                        </svg>                  
                    </div>
                    <div class="row justify-content-center mb-5 text-muted">
                      <p>No records found</p>
                    </div>
                  </div>
                  <tbody>
                    <tr v-for="trxn in transactions.data" :key="trxn.id" >
                      <td  class="text-left">
                        <b>{{ trxn.created_at | moment("YYYY-MMM-D ") }}</b><span class="time"> <i class="fa fa-clock-o"></i> {{ trxn.created_at | moment("h:mm a") }}</span><br/>
                        <span v-for="tx in trxn.transactions" :key="tx.id" >
                          <span class="right badge badge-warning mr-1 cursor" v-tooltip="{ content: '<b>' + tx.reading_date + ': ' + tx.amountdue + '</b>', placement: 'top', classes: ['info'], targetClasses: ['it-has-a-tooltip'], offset: 10, delay: { show: 500, hide: 300, }, }">{{ tx.reading_date }}</span>
                        </span>
                        <br/>
                        <span v-show="trxn.fee_membership!=0.00" class="right badge badge-warning mr-1 cursor" v-tooltip="{ content: '<b>Membership Fee: ' + trxn.fee_membership + '</b>', placement: 'top', classes: ['info'], targetClasses: ['it-has-a-tooltip'], offset: 10, delay: { show: 500, hide: 300, }, }">MEM.</span>
                        <span v-show="trxn.fee_connection!=0.00" class="right badge badge-warning mr-1 cursor" v-tooltip="{ content: '<b>Connection Fee: ' + trxn.fee_connection + '</b>', placement: 'top', classes: ['info'], targetClasses: ['it-has-a-tooltip'], offset: 10, delay: { show: 500, hide: 300, }, }">CONN.</span>
                        <span v-show="trxn.fee_reconnection!=0.00" class="right badge badge-warning mr-1 cursor" v-tooltip="{ content: '<b>Reconnection Fee: ' + trxn.fee_reconnection + '</b>', placement: 'top', classes: ['info'], targetClasses: ['it-has-a-tooltip'], offset: 10, delay: { show: 500, hide: 300, }, }">RECONN.</span> 
                        <span v-show="trxn.fee_service!=0.00" class="right badge badge-warning mr-1 cursor" v-tooltip="{ content: '<b>Service Fee: ' + trxn.fee_service + '</b>', placement: 'top', classes: ['info'], targetClasses: ['it-has-a-tooltip'], offset: 10, delay: { show: 500, hide: 300, }, }">SERV.</span>
                        <span v-show="trxn.fee_threading!=0.00" class="right badge badge-warning mr-1 cursor" v-tooltip="{ content: '<b>Threading Fee: ' + trxn.fee_threading + '</b>', placement: 'top', classes: ['info'], targetClasses: ['it-has-a-tooltip'], offset: 10, delay: { show: 500, hide: 300, }, }">THREAD.</span>
                        <span v-show="trxn.fee_meter!=0.00" class="right badge badge-warning mr-1 cursor" v-tooltip="{ content: '<b>Water Meter: ' + trxn.fee_meter + '</b>', placement: 'top', classes: ['info'], targetClasses: ['it-has-a-tooltip'], offset: 10, delay: { show: 500, hide: 300, }, }">METER</span>
                        <span v-show="trxn.fee_materials!=0.00" class="right badge badge-warning mr-1 cursor" v-tooltip="{ content: '<b>Materials: ' + trxn.fee_materials + '</b>', placement: 'top', classes: ['info'], targetClasses: ['it-has-a-tooltip'], offset: 10, delay: { show: 500, hide: 300, }, }">MATER.</span>
                        <span v-show="trxn.fee_others!=0.00" class="right badge badge-warning mr-1 cursor" v-tooltip="{ content: '<b>Others: ' + trxn.fee_others + '</b>', placement: 'top', classes: ['info'], targetClasses: ['it-has-a-tooltip'], offset: 10, delay: { show: 500, hide: 300, }, }">OTHERS</span>
                      </td>
                      <td class="text-center"><h2 class="text-secondary">OR No. {{ trxn.or_no }}</h2></td>
                      <td class="text-right"><h2 class="text-default">₱{{ trxn.fee_total | toMoney }}</h2></td>
                      <td class="text-right"><button title="View receipt" type="button"  @click="viewReceipt(trxn)" class="btn btn-success btn-sm mt-2 ml-2" v-tooltip="{ content: '<b>View Receipt</b>', placement: 'left', classes: ['info'], targetClasses: ['it-has-a-tooltip'], offset: 10, delay: { show: 500, hide: 300, }, }"><i class="fa fa-qrcode"></i></button></td>
                    </tr>
                  </tbody>
      
                </table>
              </div>
            </div>
          </div>
          <!-- /.col-md-6 -->
        </div>
        <!-- /.row -->


      </div><!-- /.container-fluid -->
    </div>
    <!-- /.content -->

  </div>
  <!-- /.content-wrapper -->
</template>

<script>

import {mapGetters} from 'vuex'
import axios from 'axios'
import Loader from '@/components/Loader'
import Form from 'vform';
import moment from 'moment';
import { required, minLength, maxLength, sameAs, not } from 'vuelidate/lib/validators'
import printJS from "print-js";
import store from '@/vuex';

export default {
  name: 'Account',

  components: {
    Loader,
  },

  data() {
    return {
      viewmode: false,
      readingeditmode: false,
      loading: false,
      loading_pending_bills: false,
      loading_check_or: false,
      loading_consumer: false,
      reading_update: false,
      confirm : false,
      year: '',
      todaydate: '',
      totalamtpd: 0,
      disconnection: '',
      reading_year: '',
      transaction_year: '',
      consumer : {},
      readings : {},
      readings_all : {},
      transactions: {},
      search_reading : '',
      consumertype : '',
      pipe : '',
      surcharge: '',
      senior: '',
      pwd: '',
      res_bracket1: '',
      res_bracket2: '',
      res_bracket3: '',
      res_bracket4: '',
      res_bracket5: '',
      res_bracket6: '',
      com_bracket1: '',
      com_bracket2: '',
      com_bracket3: '',
      com_bracket3: '',
      com_bracket4: '',
      com_bracket5: '',
      com_bracket6: '',
      com_bracket7: '',
      com_bracket8: '',
      money: {
        decimal: '.',
        thousands: ',',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: true /* doesn't work with directive */
      },
      form_payment: new Form({
        fee_membership: 0,
        fee_connection: 0,
        fee_reconnection: 0,
        fee_service: 0,
        fee_threading: 0,
        fee_meter: 0,
        fee_materials: 0,
        fee_others: 0,
        fee_total: 0,
        or_no: '',
        or_date: '',
        cashier: '',
        senior_no: '',
        pwd_no: '',
        consumer_id: '',
        consumer_name: '',
        consumer_location: '',
        trxns: [{
                  id: '',
                  payment_id: '',
                  reading_date: '',
                  pres_reading: '',
                  prev_reading: '',
                  consumption: '',
                  discount: '',
                  surcharge: '',
                  amountdue: '',
                }],

      }),
      form_reading: new Form({
        id: '',
        cid: '',
        active: '',
        pres_reading: '',
        prev_reading: '',
        consumption: '',
        discount_type: '',
        discount: '',
        amountdue: '',
        currentcharge: '',
        created_at: '',
        due_at: '',
        disconnection: '',
      }),
      form_billing: new Form({
        id: '',
        pres_reading: '',
        prev_reading: '',
        created_at: '',
      }),
      form_consumer: new Form({
        Consumercode: '',
        Consumertype: '',
        Pipe: '',
        Lastname: '',
        Firstname: '',
        Middlename: '',
        Extension: '',
        Address: '',
        Senior: '',
        Pwd: '',
      }),
      receipt: new Form({

      }),

    }
  },

  validations: {
    form_consumer: {
      Lastname: { required,  minLength: minLength(2),  maxLength: maxLength(60) },
      Firstname: { required,  minLength: minLength(2),  maxLength: maxLength(60) },
      Middlename: { maxLength: maxLength(60) },
      Extension: { maxLength: maxLength(10) },
      Address: { required,  minLength: minLength(2),  maxLength: maxLength(150) },
      Senior: { maxLength: maxLength(15) },
      Pwd: { maxLength: maxLength(15) },
      Consumertype: { required },
      Pipe: { required },
    }
  },

  methods: {
    convertToFloat(x) {
      if(!x) {
        return 0.00
      } else {
        
        return parseFloat(x.replace(/\$|,/g, ''));
      }
    },

    getFullname() {
      var mname = '';
      var ext = '';
      var fname = '';

      if (!this.consumer.Firstname) {
        fname = '';
      } else {
        fname = ', ' + this.consumer.Firstname;
      }

      if (this.consumer.Middlename) {
        mname = ' ' + this.consumer.Middlename.substring(0, 1) + '.';
      }

      if (this.consumer.Extension) {
        ext = ' ' + this.consumer.Extension;
      }

      return this.consumer.Lastname + ext + fname + mname
    },

    print(){

      /**/var style = document.createElement('style');
              style.innerHTML = "" +
                      " @media print { " +
                      " @page {size: A4 portrait;} #printReceipt{ bg-color:pink;} " +
                      "  }";
              window.document.head.appendChild(style);
            

    },

    computeReading: function() {
      var amount;
      var discount;


      if (this.form_reading.pres_reading === '' || this.form_reading.pres_reading < this.form_reading.prev_reading) {
        this.form_reading.consumption = 0;
        this.form_reading.currentcharge = 0;
        this.form_reading.amountdue = 0;
      } else {
        this.form_reading.consumption = this.form_reading.pres_reading - this.form_reading.prev_reading;
        amount = this.computeConsumption(this.form_reading.consumption)

        if (this.consumertype != 'Commercial/Industrial') {

          if (this.consumer.Senior) {
            var comp = (this.senior / 100) * amount;
            if (comp > 30.50) {
              discount = 30.50;
            } else {
              discount = (this.senior / 100) * amount;
            }
          } else if (this.consumer.Pwd) {
            var comp = (this.pwd / 100) * amount;
            if (comp > 30.50) {
              discount = 30.50;
            } else {
              discount = comp;
            }
          } else if (this.consumer.Senior && this.consumer.Pwd) {
            var comp = (this.senior / 100) * amount;
            if (comp > 30.50) {
              discount = 30.50;
            } else {
              discount = comp;
            }
          } else {
            discount = 0;
          }

        } else {
          discount = 0;
        }

        //console.log(discount);
        this.form_reading.amountdue = amount - discount;
        
        if(this.consumer.Active == 0)
        {
          this.form_reading.currentcharge = 0;
          this.form_reading.amountdue = 0;
        } else {
          this.form_reading.currentcharge = amount;
        }
      }
      
    },

    computeConsumption: function(water) {
      // For residential consumer types
      if (this.consumertype == 'Residential') {
        
        var res_bracket1_ceiling = parseFloat(this.res_bracket1);
        var res_bracket2_ceiling = (parseFloat(this.res_bracket2) * (5)) + res_bracket1_ceiling;
        var res_bracket3_ceiling = (parseFloat(this.res_bracket3) * (10)) + res_bracket2_ceiling;
        var res_bracket4_ceiling = (parseFloat(this.res_bracket4) * (10)) + res_bracket3_ceiling;
        var res_bracket5_ceiling = (parseFloat(this.res_bracket5) * (10)) + res_bracket4_ceiling;
        
        if (water >= 0 && water <= 5) {
          return parseFloat(this.res_bracket1);
        } else if (water >= 6 && water <= 10) {
          return (parseFloat(this.res_bracket2) * (water - 5)) + parseFloat(res_bracket1_ceiling);
        } else if (water >= 11 && water <= 20) {
          return (parseFloat(this.res_bracket3) * (water - 10)) + parseFloat(res_bracket2_ceiling);
        } else if (water >= 21 && water <= 30) {
          return (parseFloat(this.res_bracket4) * (water - 20)) + parseFloat(res_bracket3_ceiling);
        } else if (water >= 31 && water <= 40) {
          return (parseFloat(this.res_bracket5) * (water - 30)) + parseFloat(res_bracket4_ceiling);
        } else if (water >= 41) {
          return (parseFloat(this.res_bracket6) * (water - 40)) + parseFloat(res_bracket5_ceiling);
        }
      }
      // For commercial consumer types 1/2" pipe
      if (this.consumertype == 'Commercial/Industrial' && this.pipe == '1/2"') {
        
        var com_bracket1_ceiling = parseFloat(this.com_bracket1);
        var com_bracket2_ceiling = (parseFloat(this.com_bracket2) * (15)) + com_bracket1_ceiling;
        var com_bracket3_ceiling = (parseFloat(this.com_bracket3) * (970)) + com_bracket2_ceiling;
        
        if (water >= 0 && water <= 15) {
          return parseFloat(this.com_bracket1);
        } else if (water >= 16 && water <= 30) {
          return (parseFloat(this.com_bracket2) * (water - 15)) + parseFloat(com_bracket1_ceiling);
        } else if (water >= 31 && water <= 1000) {
          return (parseFloat(this.com_bracket3) * (water - 30)) + parseFloat(com_bracket2_ceiling);
        } else if (water >= 1001) {
          return (parseFloat(this.com_bracket4) * (water - 1000)) + parseFloat(com_bracket3_ceiling);
        }
      }
      // For commercial consumer types 2" pipe
      if (this.consumertype == 'Commercial/Industrial' && this.pipe == '2"') {

        var com_bracket5_ceiling = parseFloat(this.com_bracket5);
        var com_bracket6_ceiling = (parseFloat(this.com_bracket6) * (15)) + com_bracket5_ceiling;
        var com_bracket7_ceiling = (parseFloat(this.com_bracket7) * (970)) + com_bracket6_ceiling;

        if (water >= 0 && water <= 15) {
          return parseFloat(this.com_bracket5);
        } else if (water >= 16 && water <= 30) {
           return (parseFloat(this.com_bracket6) * (water - 15)) + parseFloat(com_bracket5_ceiling);
        } else if (water >= 31 && water <= 1000) {
          return (parseFloat(this.com_bracket7) * (water - 30)) + parseFloat(com_bracket6_ceiling);
        } else if (water >= 1001) {
          return (parseFloat(this.com_bracket8) * (water - 1000)) + parseFloat(com_bracket7_ceiling);
        }
      }
    }, 

    computeSurcharge: function(fee, current) {
      var compute = (parseFloat(fee) / 100) * parseFloat(current);
      return compute.toString();
    },

    computeDiscount: function(fee, current, type) {
      var comp;
      comp = (fee / 100) * current

      if (type != 'Commercial/Industrial') {
        if (comp > 30.50) {
          return 30.50;
        } else {
          return comp;
        }

      } else {
        return comp;
      }
      
    },

    computeAmountdue: function(current, surcharge, discount ) {
      return current + surcharge - discount;
    },
    
    async setServiceStatus(id, status) {
      this.$Progress.start();
      await this.form_consumer.put('api/consumers/' + id +  '/status/' + status)
      .then(()=>{
        this.$swal.fire({
          icon: 'success',
          title: 'Status updated'
        })
        this.$Progress.finish();
        this.loadDataConsumer();
      }).catch(()=>{
        this.$swal.fire({
          icon: 'error',
          title: 'Status update failed'
        });
        this.$Progress.fail();
      })
    },

    async getResults(page = 1) {
      this.$Progress.start();
      let query = this.search_reading;

      if(query == '') {
        await axios.get('api/readings/' + this.$route.params.id + '?page=' + page).then(response => { this.readings = response.data; });
      } else {
        await axios.get('api/readings/' + this.$route.params.id + '?year=' + query +'&page=' + page).then(response => { this.readings = response.data; }); 
      }
      this.$Progress.finish();
    },

    // sample toast
    toggleToast() {
      this.$Progress.start();
      this.$swal.fire({
        icon: 'info',
        title: 'Setup this view first'
      })
      this.$Progress.finish();
    },

    editConsumer(consumer) {
      this.editmode = true;
      this.form_consumer.reset();
      this.form_consumer.clear();
      this.form_consumer.fill(consumer);
      $('#consumermodal').modal('show');
    },

    updateConsumer() {
      this.$Progress.start();
      if(!this.$v.$invalid) {
        try {
          
          this.form_consumer.put('api/consumers/' + this.consumer.id)
          .then(()=>{
                this.$swal.fire({
                  icon: 'success',
                  title: 'Consumer updated'
                })
                this.$Progress.finish();
                this.loadDataConsumer();
                $('#consumermodal').modal('hide')
          }).catch(()=>{
                this.$swal.fire({
                  icon: 'error',
                  title: 'Consumer update failed'
                });
                this.$Progress.fail();
          })
        } catch (e) {
            this.error = e.response.data.message;
            this.$Progress.fail();
        }
      } else {
        this.$Progress.fail();
      }
      //this.$v.$reset();
    },

    total_due(dueparam) {
      if (!dueparam) {
        //
      } else {
        return dueparam.reduce((tot, val) => {
          var sum;
          //sum = tot + parseFloat(val.amountdue.replace(/,/g, ''));
          sum = tot + parseFloat(val.amountdue);
          return sum;
        }, 0);
      }
    },

    addTrxn(param_reading_id, param_reading_date, param_pres_reading, param_prev_reading, param_consumption, param_discount, param_currentcharge, param_due_at) {
        
        const current = new Date();
        const timestamp =  moment(String(current)).format('YYYY-MM-DD HH:mm:ss');
        var scharge;
        var compute_discount;
        var due_at = moment(String(param_due_at)).add(1, 'days').format('YYYY-MM-DD HH:mm:ss');

        if(timestamp > due_at) {
          scharge = parseFloat(this.computeSurcharge(this.surcharge, param_currentcharge)).toFixed(2);
        } else {
          scharge = "0.00";
        }

        if(scharge != "0.00") {
          compute_discount = "0.00"
        } else {
          compute_discount = parseFloat(this.computeDiscount(param_discount, param_currentcharge, this.consumer.consumertype)).toFixed(2);
        }


        
        this.form_payment.trxns.push({
            id: param_reading_id,
            reading_date: moment(param_reading_date).format('YYYY-MM-DD'),
            pres_reading: param_pres_reading,
            prev_reading: param_prev_reading,
            consumption: param_consumption,
            discount: compute_discount,
            surcharge: scharge,
            currentcharge: param_currentcharge,
            amountdue:  parseFloat(parseFloat(param_currentcharge) + parseFloat(scharge) - parseFloat(compute_discount)).toFixed(2),
        })

        this.form_payment.trxns.sort((a, b) => Number(a.id) - Number(b.id));

    },
    removeTrxn(rid) {
        //this.form_payment.trxns.splice(id, 1);
        let i = this.form_payment.trxns.map(item => item.id).indexOf(rid) // find index of your object
        this.form_payment.trxns.splice(i, 1) // remove it from array
    },

    // ####################### START PAYMENT METHODS ################################
    async newPayment(reading) {
      
      this.viewmode = false;
      this.form_payment.reset();
      this.form_payment.clear();

      const current = new Date();
      const timestamp =  moment(String(current)).format('YYYY-MM-DD HH:mm:ss');
      
      this.form_payment.or_date = timestamp;
      this.form_payment.consumer_id = this.consumer.id;
      this.form_payment.consumer_name = this.consumer.Lastname + ', ' + this.consumer.Firstname;
      this.form_payment.consumer_location =  this.consumer.Location;
      this.form_payment.cashier = this.user.name;
      this.form_payment.senior_no = this.consumer.Senior;
      this.form_payment.pwd_no = this.consumer.Pwd;
      
      this.getActiveOR();
      this.form_payment.trxns.splice(0, 1)
      this.addTrxn(reading.id, reading.created_at, reading.pres_reading, reading.prev_reading, reading.consumption, reading.discount, reading.currentcharge, reading.due_at)
      
      $('#paymentmodal').modal('show');
      this.loading_pending_bills = true;
      await axios.get('api/readings/' + this.$route.params.id + '/all').then(({ data }) => (this.readings_all = data));
      this.loading_pending_bills = false;
    },

    viewPayment(transaction) {
      this.viewmode = true;
      this.form_payment.reset();
      this.form_payment.clear();
      this.form_payment.fill(transaction.payments);
      
      this.form_payment.pres_reading = transaction.pres_reading;
      this.form_payment.prev_reading = transaction.prev_reading;
      this.form_payment.consumption = transaction.consumption;

      //this.form_payment.cashier = transaction.payments.cashier;
      //this.form_payment.or_date = transaction.payments.created_at;

      $('#paymentmodal').modal('show');
    },

    viewReceipt(transaction) {
      this.form_payment.reset();
      this.form_payment.clear();

      this.form_payment.or_no = transaction.or_no;
      this.form_payment.or_date = transaction.created_at;

      this.form_payment.fee_connection = transaction.fee_connection;
      this.form_payment.fee_materials = transaction.fee_materials;
      this.form_payment.fee_membership = transaction.fee_membership;
      this.form_payment.fee_meter = transaction.fee_meter;
      this.form_payment.fee_others = transaction.fee_others;
      this.form_payment.fee_reconnection = transaction.fee_reconnection;
      this.form_payment.fee_service = transaction.fee_service;
      this.form_payment.fee_threading = transaction.fee_threading;

      this.form_payment.cashier = transaction.cashier;
      this.form_payment.senior_no = this.consumer.Senior;
      this.form_payment.pwd_no = this.consumer.Pwd;

      this.form_payment.trxns.splice(0, 1);

      for (let i = 0; i < transaction.transactions.length; i++) {
        this.form_payment.trxns.push(transaction.transactions[i]);
      }

      //console.log(transaction.fee_total);

      $('#receiptmodal').modal('show');
    },

    // Save new reading
    savePayment() {
      this.$Progress.start();
      this.form_payment.post('api/payments')
      .then(()=>{
            this.$swal.fire({
              icon: 'success',
              title: 'Payment processed'
            })
            this.$Progress.finish();
            //ask if cashier wants to print transaction
            this.loadDataTransaction();
            this.loadDataReading();
      }).catch(()=>{
            this.$swal.fire({
              icon: 'error',
              title: 'Payment failed'
            });
            this.$Progress.fail();
      });

      //this.viewmode = true;
      $('#paymentmodal').modal('hide')
      

    },

    // ####################### END PAYMENT METHODS ################################

    // ####################### START READING METHODS ################################
    newReading () {
      this.readingeditmode = false;

      

      this.form_reading.reset();
      this.form_reading.clear();
      this.form_reading.consumption = 0;
      this.form_reading.currentcharge = 0;
      this.form_reading.active = this.consumer.Active;

      if (this.consumer.Consumertype != 'Commercial/Industrial') {
        if (this.consumer.Senior != null && this.consumer.Pwd == null) {
          this.form_reading.discount = this.senior;
          this.form_reading.discount_type = 'Senior';
        } else if (this.consumer.Pwd != null && this.consumer.Senior == null) {
          this.form_reading.discount = this.pwd; 
          this.form_reading.discount_type = 'Pwd';
        } else if (this.consumer.Senior != null && this.consumer.Pwd != null) {
          this.form_reading.discount = this.senior; 
          this.form_reading.discount_type = 'Senior/Pwd';
        } else if (this.consumer.Senior == null && this.consumer.Pwd == null) {
          this.form_reading.discount = 0; 
          this.form_reading.discount_type = 'NA';
        } else {
            this.form_reading.discount = 0;
            this.form_reading.discount_type = 'NA';
        }
      } else {
        this.form_reading.discount = 0; 
        this.form_reading.discount_type = 'NA';
      }

      const current = new Date();
      const timestamp =  moment(String(current)).format('YYYY-MM-DD HH:mm:ss');
      this.todaydate = timestamp;
      const date =  (current.getMonth() +1 ) + '/' + current.getDate() + '/' + current.getFullYear();
      var next_month = new Date(); // Now
      next_month.setDate(next_month.getDate() + 30);
      const month = next_month.getMonth() + 1;
      const year = next_month.getFullYear()
      const due = year + '-' + month + '-18 ';

      axios.get('api/readings/' + this.$route.params.id + '/lastreading').then( 
      response => {
        // set form.prev_reading to zero (0) if axios has no return value
        if (!response.data || response.data.length === 0) {
          this.form_reading.prev_reading = 0;
        } else {
          this.form_reading.prev_reading = response.data.pres_reading;
        }
      }).catch(function (error) {
        console.log(error);
      });
      
      this.form_reading.created_at = date;
      this.form_reading.due_at = moment(due).format('YYYY-MM-DD HH:mm:ss');
      this.confirm = false;
      this.form_reading.cid = this.$route.params.id;
      this.getDisconnectionDate(this.form_reading);
      $('#readingmodal').modal('show');
    },

    // Save new reading
    saveReading() {
      this.$Progress.start();
      this.loading = true;
      this.reading_update = false;
      this.form_reading.post('api/readings')
      .then(()=>{
            this.$swal.fire({
              icon: 'success',
              title: 'Reading input saved'
            })
            this.$Progress.finish();
            this.loadDataReading();
            this.readingeditmode = true;
            this.loading = false;
      }).catch(()=>{
            this.$swal.fire({
              icon: 'error',
              title: 'Reading input failed'
            });
            this.$Progress.fail();
      })

      this.layoutIframe();
    },

    // Update existing reading reading by id
    updateReading() {
      this.$Progress.start();
      this.form_reading.put('api/readings/' + this.form_reading.id)
      .then(()=>{
            this.$swal.fire({
              icon: 'success',
              title: 'Reading input updated'
            })
            this.$Progress.finish();
            this.loadDataReading();
            this.readingeditmode = true;
      }).catch(()=>{
            this.$swal.fire({
              icon: 'error',
              title: 'Reading updating failed'
            });
            this.$Progress.fail();
      })
    },

    sleep(ms) {
      return new Promise((resolve) => {
        setTimeout(resolve, ms);
      });
    },

    async getDisconnectionDate(reading) {
      this.disconnection = await moment().set('year', moment(String(reading.due_at)).format('YYYY')).set('month', moment(String(reading.due_at)).format('MM')).set('date', 1).isoWeekday(8).format('MM/DD/YYYY')      
    },

    // Edit modal reading
    async viewReading(reading) {
      this.reading_update = true;
      this.getDisconnectionDate(reading);
      const current = new Date();
      const timestamp =  moment(String(current)).format('YYYY-MM-DD HH:mm:ss');
      //const duedate =  moment(String(this.form_reading.due_at)).format('YYYY-MM-DD HH:mm:ss');
      this.todaydate = timestamp;
      this.readingeditmode = true;
      this.form_reading.reset();
      this.form_reading.clear();
      await this.form_reading.fill(reading);
      //console.log(this.disconnection);
      //await this.sleep(1000);
      this.layoutIframe();
      $('#readingmodal').modal('show');
    },

    // ####################### END READING METHODS ################################

    // Get latest active OR for new payment
    async getActiveOR() {
      this.loading_check_or = true;
      await axios.get('api/booklets/or/' + this.user.id).then( 
      response => {
        // set form.prev_reading to zero (0) if axios has no return value
        if (!response.data || response.data.length === 0) {
          this.form_payment.or_no = 'DEPLETED';
        } else {
          this.form_payment.or_no = response.data.or_no;
        }
      }).catch(function (error) {
        console.log(error);
      });
      this.loading_check_or = false;
    },

    // Cancel OR
    resetMeter() {
      this.form_reading.reset();
      this.form_reading.clear();
      this.form_reading.consumption = 0;
      this.form_reading.currentcharge = 0;
      this.form_reading.active = 2;
      this.form_reading.discount_type = 'NA';
      this.form_reading.discount = 0; 
      this.form_reading.amountdue = 0;
      this.form_reading.pres_reading = 0;

      const current = new Date();
      const timestamp =  moment(String(current)).format('YYYY-MM-DD HH:mm:ss');
      this.todaydate = timestamp;
      const date =  (current.getMonth() +1 ) + '/' + current.getDate() + '/' + current.getFullYear();

      axios.get('api/readings/' + this.$route.params.id + '/lastreading').then( 
      response => {
        // set form.prev_reading to zero (0) if axios has no return value
        if (!response.data || response.data.length === 0) {
          this.form_reading.prev_reading = 0;
        } else {
          this.form_reading.prev_reading = response.data.pres_reading;
        }
      }).catch(function (error) {
        console.log(error);
      });
      
      this.form_reading.created_at = date;
      this.form_reading.due_at = timestamp;
      this.form_reading.cid = this.$route.params.id;

      swal.fire({
          title: 'Are you sure?',
          text: "This action will reset the present reading back to zero",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, confirm reset',
          cancelButtonText: 'No'
          }).then((result)=>{
            if (result.value) {
              this.$Progress.start();
              this.form_reading.post('api/readings')
              .then(()=>{
                swal.fire(
                  'Processed!',
                  'Meter successfully reset.',
                  'success'
                );
                this.$Progress.finish();
                this.loadDataReading();
              }).catch(()=>{
                this.$Progress.fail();
                swal.fire(
                  'Failed!', 
                  'There was something wrong', 
                  'warning'
                )
              });
            }  
        })
    },

    // Cancel OR
    cancelOr(orno) {
      swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, cancel this OR',
          cancelButtonText: 'No'
          }).then((result)=>{

            if (result.value) {
              //send delete ajax tru api request to server
              this.form_payment.put('api/booklets/or/' + orno + '/Cancelled')
              .then(()=>{
                swal.fire(
                  'Processed!',
                  'OR successfully cancelled.',
                  'success'
                );
                this.getActiveOR();
              }).catch(()=>{
                swal.fire(
                  'Failed!', 
                  'There was something wrong', 
                  'warning'
                )
              });
            }  
        })
    },

    // Update existing reading
    updateBilling() {
      this.$Progress.start();
      this.form_billing.put('api/readings/'+this.form_billing.id).then(()=>{
              this.$swal.fire({
                icon: 'success',
                title: 'Billing updated'
              })
              this.$Progress.finish();
              this.loadDataReading();
               $('#billingmodal').modal('hide')
            }).catch(()=>{
              this.$swal.fire({
                icon: 'error',
                title: 'Billing updating failed'
              });
              this.$Progress.fail();
            });
    },

    async layoutIframe() {
      const content = await document.getElementById('printBilling').innerHTML;
      let iFrame = document.getElementById('print-iframe');
      const printHtml = `<html>
				<head>
					<meta charset="utf-8">
					<title>Name Card</title>
				</head>
        <style>
            body {
              font-family: Monospace;
              font-size: 13px;
              width:240px;
            }
            td { font-size: 12px }
            .big {font-size: 15px !important;}
            .mid {font-size: 14px !important;}
            .rheader {font-size: 11px !important;}
            .hideinprint {
              display: none;
            }
        </style>
				<body>${content}</body>
			</html>`;
      iFrame.contentDocument.body.innerHTML = printHtml;
      iFrame.focus();
    },

    async samplePrint() {
      let iFrame = document.getElementById('print-iframe');
      iFrame.focus();
      //setTimeout(iFrame.contentWindow.print(), 2000)
      await iFrame.contentWindow.print();
    },

    printOR() {
      const content = document.getElementById('printReceiptOrig').innerHTML;
      let iFrame = document.getElementById('print-iframe');
      const printHtml = `<html>
				<head>
					<meta charset="utf-8">
					<title>Print OR</title>
				</head>
        <style>
            body {
              font-family: 'saxMono', Arial;
              font-size: 15px;
              width:240px;
            }
            td { font-size: 15px }
            .big {font-size: 30px !important;}
            .mid {font-size: 20px !important;}
            .hideinprint {              
              display: none;
            }
        </style>
				<body>${content}</body>
			</html>`;
      iFrame.contentDocument.body.innerHTML = printHtml;
      iFrame.focus();
      iFrame.contentWindow.print();
    },

    async loadDataConsumer() {
      this.$Progress.start();
      // load consumers data
      await axios.get('api/consumers/' + this.$route.params.id).then(({ data }) => (this.consumer = data));
      this.consumertype = this.consumer.Consumertype;
      this.pipe = this.consumer.Pipe;
      this.$Progress.finish();
    },

    setYear(yr) {
      this.year = yr;
    },

    async loadDataTransaction(year) {
      const current = new Date();

      if(!year) {
        this.transaction_year = current.getFullYear();
      } else {
        this.transaction_year = year;
      }
      
      this.$Progress.start();
      //await axios.get('api/readings/'+ this.$route.params.id + '/transactions/' + this.transaction_year).then(({ data }) => (this.transactions = data));
      await axios.get('api/payments/'+ this.$route.params.id + '/' + this.transaction_year).then(({ data }) => (this.transactions = data));
      this.$Progress.finish();
    },

    async loadDataReading(year) {
      const current = new Date();
      
      if(!year) {
        this.reading_year = current.getFullYear();
      } else {
        this.reading_year = year;
      }

      this.$Progress.start();
      // load readings data
      await axios.get('api/readings/' + this.$route.params.id + '/year/' + this.reading_year).then(({ data }) => (this.readings = data));
      this.$Progress.finish();
    },

    async loadData() {
      //initial axios header/authorization of api when vue component is mounted
      this.loading = true;

      const current = new Date();
      this.transaction_year = current.getFullYear();
      this.reading_year = current.getFullYear();

      // load consumers data
      await axios.get('api/consumers/' + this.$route.params.id).then(({ data }) => (this.consumer = data));
      this.consumertype = this.consumer.Consumertype;
      this.pipe = this.consumer.Pipe;
      // load readings data
      await axios.get('api/readings/' + this.$route.params.id + '/year/' + this.reading_year).then(({ data }) => (this.readings = data));
      // Load transaction data
      //await axios.get('api/readings/'+ this.$route.params.id + '/transactions/' + this.transaction_year).then(({ data }) => (this.transactions = data));
      await axios.get('api/payments/'+ this.$route.params.id + '/' + this.transaction_year).then(({ data }) => (this.transactions = data));
      // load and set settings data
      await axios.get('api/settings').then(({ data }) => (this.settings = data));

      this.surcharge = this.settings[0].value;        // Surcharge
      this.senior = this.settings[1].value;           // Senior Discount
      this.pwd = this.settings[2].value;              // PWD Discount

      this.res_bracket1 = this.settings[3].value;     // Residential Minimun Rate 0-5 cu.m.
      this.res_bracket2 = this.settings[4].value;     // 6-10 cu.m.
      this.res_bracket3 = this.settings[5].value;     // 11-20 cu.m.
      this.res_bracket4 = this.settings[6].value;     // 21-30 cu.m.
      this.res_bracket5 = this.settings[7].value;     // 31-40 cu.m.
      this.res_bracket6 = this.settings[8].value;     // Over 40 cu.m.

      this.com_bracket1 = this.settings[9].value;     // Commercial Minimun Rate 0-15 cu.m. (1/2")
      this.com_bracket2 = this.settings[10].value;    // 16-30 cu.m. (1/2")
      this.com_bracket3 = this.settings[11].value;    // 31-1000 cu.m. (1/2")
      this.com_bracket4 = this.settings[12].value;    // Over 1000 cu.m. (1/2")

      this.com_bracket5 = this.settings[13].value;    // Commercial Minimun Rate 0-15 cu.m. (2")
      this.com_bracket6 = this.settings[14].value;    // 16-30 cu.m. (2")
      this.com_bracket7 = this.settings[15].value;    // 31-1000 cu.m. (2")
      this.com_bracket8 = this.settings[16].value;    // Over 1000 cu.m. (2")

      this.loading = false;
    },

    xconvertFloat(param) {
      return parseFloat(param);
    },

  },

  computed: {
    ...mapGetters(['user']),
/*
    getUserInfo(){
      return this.$store.state.user;
    },*/

    years() {
      const year = new Date().getFullYear()
      return Array.from({length: year - 2019}, (value, index) => 2020 + index)
    },

    getTotalAmountPaid() {
      var total = 0;

      var xamountdue = this.convertToFloat(this.form_payment.amountdue);
      var xmembership = this.convertToFloat(this.form_payment.fee_membership);
      var xconnection = this.convertToFloat(this.form_payment.fee_connection);
      var xreconnection = this.convertToFloat(this.form_payment.fee_reconnection);
      var xservice = this.convertToFloat(this.form_payment.fee_service);
      var xsurcharge = this.convertToFloat(this.form_payment.fee_surcharge);
      var xthreading = this.convertToFloat(this.form_payment.fee_threading);
      var xmeter = this.convertToFloat(this.form_payment.fee_meter);
      var xmaterials = this.convertToFloat(this.form_payment.fee_materials);
      var xothers = this.convertToFloat(this.form_payment.fee_others);
      
      total = xamountdue + xmembership + xconnection + xreconnection + xservice + xsurcharge + xthreading + xmeter + xmaterials + xothers + parseFloat(this.total_due(this.form_payment.trxns));

      if (isNaN(total)) {
        this.form_payment.fee_total = 0;
      } else {
        this.form_payment.fee_total = total.toFixed(2);
      }

      this.totalamtpd = this.$options.filters.moneyToWordsPesos(total)

      return total;
    },

  },

  mounted() {
    this.$Progress.start();
    const today = new Date();
    this.loadData();
    this.year = today.getFullYear();
    this.$Progress.finish();
  },


}
</script>
<style scoped>
.cursor {
  cursor: pointer;
}
.card-title {
  font-size: 1.3rem !important;
}
.dropdown-item {
  cursor: pointer;
}
.custom-scrollbar {
  position: relative;
  height: 720px;
  overflow: auto;
  padding: 0;
}
#style-7::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
	border-radius: 10px;
}

#style-7::-webkit-scrollbar
{
	width: 10px;
	background-color: #F5F5F5;
}

#style-7::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	background-image: -webkit-gradient(linear,
									   left bottom,
									   left top,
									   color-stop(0.44, rgb(122,153,217)),
									   color-stop(0.72, rgb(73,125,189)),
									   color-stop(0.86, rgb(28,58,148)));
}
.big {font-size: 21px !important;}
.mid {font-size: 21px !important;}
/*
@media print{ 
   .big {font-size: 220px !important; color: red;}
   h1 { color: red; }
   #printBilling {
     width:300px;
   }
} */
@media print {
  .receiptfont {
    font-family: Consolas;
  }

  .printed-div{
       display:block;
   }

   .logo-print{
       width:291px;
       height:109px;
       background-image: url('../assets/logo.png');
       background-repeat: no-repeat;
   }
  
}

@media all{
   printed-div{
       display:none;
   }
}

.logo-print{
    width:68px;
    height:69px;
    background-image: url('../assets/logo.png');
    background-repeat: no-repeat;
}

.dropdown-menu {
    min-width: 5rem;
}

</style>
